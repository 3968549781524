/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {IFilter, ReportType, ISkillTags, Cookie} from "companion";
import {BsDatepickerConfig, BsDatepickerViewMode} from "ngx-bootstrap/datepicker";
import {LocalizationService} from "../../localization/localization.service";
import {DashboardService} from "../dashboard.service";
import {DatatableComponent, TableColumn} from "@swimlane/ngx-datatable";
import {distinctUntilChanged, map} from "rxjs/operators";
import {Subscription} from "rxjs";
import {Store} from "../../Store/store.service";
import {PickerMode} from "../shared/range-picker.component";
import { addDays, differenceInDays } from "date-fns";

interface IReport {
  title: string;
  id: string;
}

// Dashboard Reports tab
@Component({
  selector: "dashboard-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"]
})
export class DashboardReportsComponent implements OnInit, OnDestroy {

  @ViewChild("reportDatatable") reportDatatable: DatatableComponent;

  reports: IReport[];
  selectedReport: IReport;
  selectedRange: Date[];
  isFullscreen = false;
  rangePickerConfig: Partial<BsDatepickerConfig>;
  today: Date;
  dataRows: Object[];
  filteredDataRows = [];
  columns: TableColumn[];
  storeSubscription: Subscription;
  maxDaysToPreview: number = 31;
  maxDaysToDownload: number = 366;
  rangePreview: {start: Date, end: Date, endDisplay: Date, subset: boolean};
  isDownloading = false;
  pendingDownloadFileName: string;
  isGeneratingPreview = false;
  generatedPreview = false;
  systemReportFrequencyOptions: string[];
  selectedSystemReportFrequency: string;

  constructor(private dashboardService: DashboardService,
              private localizationService: LocalizationService,
              private store: Store) {
  }

  ngOnInit(): void {
    this.init();
  }


  ngOnDestroy(): void {
    this.storeSubscription?.unsubscribe();
  }


  datePickerChanged() {
     this.rangePreview = null;
     this.generatedPreview = false;
  }

  selectReport(report: IReport) {
     this.selectedReport = report;
     this.rangePreview = null;
     this.generatedPreview = false;
  }

  reportFullScreen() {
    this.isFullscreen = !this.isFullscreen;
    setTimeout(() => {
      this.reportDatatable?.recalculate();
    });

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.filteredDataRows.filter((d) => {
      return d[this.columns[1].prop].toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.dataRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.reportDatatable.offset = 0;
  }

  download() {
    const message = this.validateDates(false);
    if (message) {
      bootbox.alert({message: message});
      return;
    }

    const filter = this.getFilter(false, "csv");

    const start = this.getStartDate;
    const end = this.getEndDate;
    this.pendingDownloadFileName = 
      start.toLocaleDateString().replace(/\//g, "") +
      "_" +
      end.toLocaleDateString().replace(/\//g, "") +
      "_" +
      filter.reportType +
      ".csv";

    this.isDownloading = true;
    this.dashboardService.fetchReportAsync(filter)
    .then((taskId) =>{
      this.dashboardService.isLoading = true;
      this.dashboardService.monitorAsyncTask({
        taskId: taskId,
        responseType: "text",
        completeFn: (report: any) => {
          this.isDownloading = false;
          if (!report || report.error) {
            let errorMessage = "";
            if (!report || report.error) {
              if (report && report.error) {
                errorMessage = report.error.message;
              } else {
                errorMessage = "Failed to generate report";
              }
              // What to do with error message?
              return;
            }
          }
    
          let blob: Blob;
          
          blob = new Blob([report], { type: "text/plain;charset=utf-8" });
          saveAs(blob, this.pendingDownloadFileName);
          this.dashboardService.isLoading = false;
          this.isDownloading = false;
        },
        errorFn: () => {
          console.log("Async report download failed. Check server for details.");
          this.dashboardService.isLoading = false;
          this.isDownloading = false;
          this.pendingDownloadFileName = null;
        }
      });
    })
    .catch((error) => {
      console.log("Failed to fetch report for download: ", error);
      // Cleanup stuff
      this.isDownloading = false;
      this.pendingDownloadFileName = null;
    });
  }

  getReport(): void {
    if (!this.selectedReport || !this.selectedRange) {
      return;
    }
    if (this.selectedReport.id == "system" && !this.selectedSystemReportFrequency) {
       // For system reports, we can proceed with the report generation only if the report frequency is selected
       return;
    }

    const message = this.validateDates(true);
    if (message) {
      bootbox.alert({
        message: message
      });
      return;
    }

    const filter = this.getFilter();

    this.isGeneratingPreview = true;
    this.dashboardService.fetchReportAsync(filter)
    .then((taskId) =>{
      this.dashboardService.isLoading = true;
      this.dashboardService.monitorAsyncTask({
        taskId: taskId,
        responseType: "json",
        completeFn: (report: any) => {
          this.isGeneratingPreview = false;
          if (!report?.data || !report?.definition?.fields) {
            return;
          }

          this.columns = report.definition.fields.map(def => {
            const column: TableColumn = {
              name: def.displayName,
              prop: def.fieldName,
            };

            const dateFieldS = ["date",  "loginat", "logoutat", "callendat", "callringat", "callstartat"];

            if (dateFieldS.includes(def.fieldName)) {
              column.comparator =  (valueA, valueB, rowA, rowB, sortDirection) => {
                const splitA = valueA.split(" ");
                const splitB = valueB.split(" ");
                if (splitA.length < 1 || splitB.length < 1 ) {
                  return 0;
                }
                const dateA = splitA[0];
                const dateB = splitB[0];
                const dateFieldA = dateA.split("/");
                const dateFieldB = dateB.split("/");
                if ( dateFieldA.length < 3 || dateFieldB.length < 3 ) {
                  return 0;
                }
                let concatA = dateFieldA[2] + dateFieldA[0] + dateFieldA[1];
                let concatB = dateFieldB[2] + dateFieldB[0] + dateFieldB[1];
                if (splitA.length >= 2 && splitB.length >= 2 ) {
                   // add time fields to date only if available; otherwiise we compare only the dates
                   concatA = concatA + splitA[1];
                   concatB = concatB + splitB[1];
                }
                if (concatA < concatB) {
                  return -1;
                }
                if (concatA > concatB) {
                  return 1;
                }

                return 0;
              };
            }
            return column;
          });

          this.dataRows = report.data;
          this.filteredDataRows = [...this.dataRows];
          this.dashboardService.isLoading = false;
          this.isGeneratingPreview = false;
          this.generatedPreview = true;
        },
        errorFn: () => {
          console.log("Async report request failed. Check server for details.");
          this.dashboardService.isLoading = false;
          this.isGeneratingPreview = false;
        }
      });
    })
    .catch((error) => {
      console.log("Failed to fetch report for display: ", error);
      this.isGeneratingPreview = false;
    });
  }

  private init() {
    this.today = new Date();
    this.rangePickerConfig = {
      containerClass: "theme-default",
      minMode: PickerMode.day as BsDatepickerViewMode,
      rangeInputFormat: "L"
    };

    this.reports = [
      {
        title: "System Report",
        id: "system"
      },
      {
        title: "Agent Report",
        id: "agent"
      },
      {
        title: "Customer Report",
        id: "client"
      },
      {
        title: "Chat Messages",
        id: "chat"
      }
    ];
    this.systemReportFrequencyOptions = ["ByHour", "ByDay", "ByWeek", "ByMonth"];
    this.selectedSystemReportFrequency =  Cookie.getCookie("SystemReportFrequency") || "ByHour";

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.dashboardGroup), distinctUntilChanged())
      .subscribe(dashboardGroup => {
        if(dashboardGroup)
        {
          this.getReport();
        }
      });

      this.storeSubscription = this.store.changes
      .pipe(map(data => data.language), distinctUntilChanged())
      .subscribe(language => {
        if (language) {
          this.getReport();
        }
      });
      this.storeSubscription = this.store.changes
      .pipe(map(data => data.category), distinctUntilChanged())
      .subscribe(category => {
        if (category) {
          this.getReport();
        }
      });

    if (this.localizationService.myLocalizationData?.reporting?.maxDownload) {
      this.maxDaysToDownload = this.localizationService.myLocalizationData.reporting.maxDownload;
    }
    if (this.localizationService.myLocalizationData?.reporting?.maxPreview) {
      this.maxDaysToPreview = this.localizationService.myLocalizationData.reporting.maxPreview;
    }
  }

  private get getStartDate(): Date {
    return this.selectedRange[0];
  }

  private get getEndDate(): Date {
    return addDays(this.selectedRange[1], 1);
  }

  private getFilter(isPreview = true, outType: "json" | "csv" = "json"): IFilter {
    const filter: IFilter = Object();
    filter.outType = outType;
    filter.withReportDefinition = true;
    switch (this.selectedReport.id) {
      case "system":
        filter.reportType = ReportType[ReportType.VCCSystemReport];
        break;
      case "agent":
        filter.reportType = ReportType[ReportType.VCCAgentReport];
        break;
      case "client":
        filter.reportType = ReportType[ReportType.VCCCustomerReport];
        break;
      case "kiosk":
        filter.reportType = ReportType[ReportType.VCCKioskReport];
        break;
      case "chat":
        filter.reportType = ReportType[ReportType.ChatReport];
        break;
    }

    filter.groupIds = this.dashboardService.getSelectedGroupAndDescendantIds();
    
    let start: Date;
    let end: Date;
    if (isPreview) {
      start = this.rangePreview ? this.rangePreview.start : this.getStartDate;
      end = this.rangePreview ? this.rangePreview.end : this.getEndDate;
    } else {
      start = this.getStartDate;
      end = this.getEndDate;
    }

    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    filter.start = start;
    filter.end = end;
    filter.timeDifferenceByHour = (start.getTimezoneOffset() / 60) * -1;

    filter.systemReportFrequency = this.selectedSystemReportFrequency;

    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }

    filter.skillTags = filterTags;

    return filter;
  }

  private validateDates(isPreview) {
    const start = this.getStartDate;
    const end = this.getEndDate;
    let error: string = null;

    const daysBetweenDates = differenceInDays(end, start);
    if (daysBetweenDates > this.maxDaysToDownload) {
      error = `The range is too large to get the report. You can select up to ${this.maxDaysToDownload} days`;
      return error;
    }

    if (isPreview == false)
    {
       // if not preview (i.e. download), no need to limit the date range or set rangePreview
       return error;
    }

    if (daysBetweenDates > this.maxDaysToPreview) {
      this.rangePreview = {
        start: start,
        end: addDays(start, this.maxDaysToPreview),
        // actual end date is one off the display end date
        endDisplay: addDays(start, this.maxDaysToPreview-1),
        // preview shows only a subset of selected date range
        subset: true
      };
    } else {
      this.rangePreview = {
        start: start,
        end: end,
        // actual end date is one off the display end date
        endDisplay: this.selectedRange[1],
        // preview shows full selected date range
        subset: false
      };
    }
    return error;
  }

   public get DisplaySystemReportFrequencyOptions(): boolean {
      // If current selection is System Report, then display the System Report Frequency Options
      return (this.selectedReport && this.selectedReport.id == "system");
   }

   public SystemReportFrequencyChange() {
      this.rangePreview = null;
      this.generatedPreview = false;
      Cookie.setCookie("SystemReportFrequency", this.selectedSystemReportFrequency);
   }

   public generateReportPreview() {
      this.getReport();
   }

   public get GeneratePreviewOrDownloadIsDisabled() {
      return (!this.selectedRange || 
              !this.selectedReport || 
              (this.selectedReport.id == "system" && 
               !this.selectedSystemReportFrequency) || 
              this.isDownloading ||
              this.isGeneratingPreview);
   }

   public get PreviewAvailable() {
      return (this.selectedRange && 
              this.selectedReport && 
              (this.selectedReport.id != "system" || 
               this.selectedSystemReportFrequency) && 
              this.rangePreview &&
              this.generatedPreview);
   }

}
