/**
 * Copyright Compunetix Incorporated 2016-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Ramadevi Gavirneni
 */

import { Component, Input, Output, EventEmitter } from "@angular/core";
import {
  IUser,
  IEndpoint,
  IEndpointService,
  Companion,
  IExpandedActiveConference,
  PresenceState
 } from "companion";
import { SortByPipe } from "../../shared/pipes/sort-by.pipe";
import { LocalizationService } from "../../localization/localization.service";

@Component({
  selector: "user-list",
  templateUrl: "./user-list.template.html"
})
export class UserListComponent {
  @Input() listType: string;
  @Input() viewMode: string;
  @Input() users: IUser[];
  @Input() showMyself: boolean;
  @Input() conference: IExpandedActiveConference;

  /**
   * the event emitter to send event to parent to open operator info dialog
   */
  @Output("openUserInfoModal")
  openUserInfoModalEmitter: EventEmitter<IUser> = new EventEmitter<IUser>();

  endpointService: IEndpointService = Companion.getEndpointService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;

  PresenceState: any = PresenceState;

  /**
   * view model for filter
   */
  viewModel: { filter: string } = { filter: null };

  constructor(
    private sortPipe: SortByPipe,
    public localizationService: LocalizationService
  ) {
    // nothing needed here
  }

  /**
   * sort users
   */
  public get sortedUsers(): IUser[] {
    // override list type sort?
    _.forEach(this.users, (userModel: any) => {
      // update for special case
      if (userModel.userId === Companion.getEndpointService().myEndpoint.userId) {
        userModel.sortWeight = 203; // myself should have higher priority than any other case.
      }
      else {
        userModel.sortWeight = 200;
      }
    });
    let result = this.sortPipe.transform(this.filteredUsers, ["sortWeight", "username", "index"], ["desc", "asc", "asc"]);
    return result;
  }

  /**
   * filter users by name
   */
  public get filteredUsers(): IUser[] {
    // filter our data
    let result = _.filter(this.users, (user: any) => {
      return (user.index && this.viewModel.filter && user.index.indexOf(this.viewModel.filter.toLowerCase()) > -1) || !this.viewModel.filter;
    });
    return result;
  }

  /**
   * hides active users
   */
  isUserHidden(user: IUser): boolean {
    let result: boolean = false;
    if (!this.localizationService.myLocalizationData?.participant_panel?.showOfflineUsers) {
      result = true;
    }
    if (!this.showMyself && user["_id"] === this.myEndpoint.userId) {
      result = true;
    }
    if (Companion.getEndpointService().getEndpointByUserId(user["_id"])) {
      result = true;
    }
    return result;
  }

  /**
   * tracks user by
   */
  trackByUser(index: number, userModel: IUser) {
    return userModel.username;
  }

  /**
   * reset filter
   */
  resetSearch() {
    this.viewModel.filter = null;
  }

  /**
   * Request to open operator info modal
   */
  openUserInfoModal(user: IUser) {
    this.openUserInfoModalEmitter.emit(user);
  }

}
