/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";

import { CallCenterComponent } from "./call-center.component";
import { GuestConnectComponent } from "./guest-view/guest-connect.component";
import { CallCenterListComponent } from "./call-center-list.component";

import { HttpClientModule } from "@angular/common/http";
import { LoginModule } from "../login/login.module";
import { SharedModule } from "../shared/shared.module";
import { AlertModule } from "../alert/alert.module";
import { FlavorFactoryModule } from "../flavor-factory/flavor-factory.module";
import { LayoutModule } from "client/scripts/layout/layout.module";
import { RemoteDesktopModule } from "client/scripts/remote-control/remote-desktop.module"

import { TimeService } from "../shared/services/time.service";
import { CallCenterService } from "./call-center.service";
import { HelperModule } from "../helper/helper.module";
import { DialOutModule } from "../dial-out/dial-out.module";
import { InspectModule } from "../conference/inspect/inspect.module";
import { NgxSliderModule } from "ngx-slider-v2";
import { GuestQueueScreenComponent } from "./guest-view/guest-queue-screen.component";
import { VoiceViewComponent } from "./voice-view/voice-view.component";
import { GuestInfoComponent } from "client/scripts/guest-info/guest-info.component";
import { CropperComponent } from "client/scripts/cropper/cropper.component";
import { GuestWaitComponent } from "client/scripts/call-center/guest-view/guest-wait.component";
import { CallBackPanelComponent } from "client/scripts/call-center/guest-view/call-back-panel.component";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ConferenceModule } from "../conference/conference.module";
import { OperatorInfoComponent } from "client/scripts/operator-info/operator-info.component";
import { PasscodeModule } from "../passcode/passcode.module";
import { SharedFolderModule } from "../conference/sharedfolder-modal/sharedfolder.module";
import { ImageCropperComponent } from "ngx-image-cropper";
import { UserInfoComponent } from "client/scripts/user-info/user-info.component";


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    LoginModule,
    SharedModule,
    InspectModule,
    AlertModule,
    FlavorFactoryModule,
    LayoutModule,
    HelperModule,
    ImageCropperComponent,
    DialOutModule,
    NgxSliderModule,
    ConferenceModule,
    RemoteDesktopModule,
    PasscodeModule,
    SharedFolderModule
  ],
  declarations: [
    CallCenterComponent,
    GuestConnectComponent,
    VoiceViewComponent,
    GuestQueueScreenComponent,
    CallCenterListComponent,
    GuestInfoComponent,
    CropperComponent,
    GuestWaitComponent,
    CallBackPanelComponent,
    OperatorInfoComponent,
    UserInfoComponent
  ],
  providers: [TimeService, CallCenterService],
  bootstrap: [CallCenterComponent],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [CallCenterComponent, CallCenterListComponent, GuestInfoComponent, CropperComponent, OperatorInfoComponent, UserInfoComponent]
})
export class CallCenterModule {}
