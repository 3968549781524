/**
 * Copyright Compunetix Incorporated 2016-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import {
  CameraPermission,
  EndpointUpdate,
  ICoordinates,
  IEndpoint,
  IEndpointRef,
  PresenceStatus,
  StatusReason
} from "./endpoint.interface";

import {UserService} from "../user/user.service";
import {IUserService} from "../user/user.service.interface";
import {Endpoint} from "./endpoint";
import {IActiveConference, IConferenceRef, IQueueConference, RoomType} from "../conference/conference.interface";
import {AlertCode, AlertLevel} from "../alert/alert.interface";
import {IConnectingOperator, IEndpointService} from "./endpoint.service.interface";
import {
  BrowserResourceAccessEvent,
} from "../report/report.interface";
import {VideoMediaConnectionMode} from "../services/rtc.client.interface";
import { IConferenceService } from "../conference/conference.service.interface";
import { ILocalization } from "../localization/localization.interface";
import { ConferenceService } from "../conference/conference.service";
import { IConferenceBase } from "../conference/conference.base.interface";
import { EasyRTCService } from "../services/rtc.service.easy";
import { IPCStats } from "../util/stats";
import { resolve } from "path";
import { reject } from "lodash";

const JOIN_ACK_TIMEOUT: number = 2 * 1000;
const CANCEL_CALL_TIMEOUT: number = 10 * 1000;

export interface IEndpointFindResult {
  endpoint: IEndpoint,
  list: IEndpoint[]
}

/**
 * endpoint methods delegate
 */
export class EndpointService implements IEndpointService {
  private static sharedInstance: EndpointService;
  /**
   * the endpoint object representing the current client
   */
  public myEndpoint: IEndpoint;

  /**
   * the operator endpoint I am connecting to
   */
  connectingOperator: IConnectingOperator;

  private conferenceService: IConferenceService;
  private userService: IUserService;
  private joinTimerObj: { [key: string]: any } = {};

  /**
   * This map stores conference/Queue names to has endpoint on hold flags.
   * It is used to determine if there are any queues that contain endpoints on hold when
   * checking the ready to available transiton status change timer.
   */
  private readyTransitionTriggerMap = new Map<string, boolean>()
  private localizationCallback: () => ILocalization;

  public endpoints : Map<string, Endpoint> = new Map();

  constructor() {}

  /**
   * get shared singleton object
   */
  static getSharedInstance(): IEndpointService {
    if (!this.sharedInstance) {
      this.sharedInstance = new EndpointService();
      this.sharedInstance.conferenceService = ConferenceService.getSharedInstance();
      this.sharedInstance.userService = UserService.getSharedInstance();
      this.sharedInstance.myEndpoint = new Endpoint(
        null,
        this.sharedInstance.userService.currentUser.username,
        this.sharedInstance.userService.currentUser["_id"]
      );
      this.sharedInstance.myEndpoint.skillSet = this.sharedInstance.userService.currentUser?.skillSet;
    }
    return this.sharedInstance;
  }

  // Get RT TTL for timeouts
  get disconnectTimeOut(): number {
    return EasyRTCService.getSharedInstance().rtcClient.SERVER_DISCONNECT_TIMEOUT ?
    EasyRTCService.getSharedInstance().rtcClient.SERVER_DISCONNECT_TIMEOUT * 2 :
      40 * 1000;
  }

  /**
   * find endpoint in the conference by rtcid
   * @param rtcId: stirng - rtcId to search for
   */
  findEndpoint(rtcId: string): IEndpoint {
    return this.endpoints.get(rtcId);    
  }

  registerLocalizationCallback(localizationFn: ()=>ILocalization) : void {
    this.localizationCallback = localizationFn;
  }

  fetchLocalization(): ILocalization {
    if (this.localizationCallback) {
      return (this.localizationCallback());
    } else {
      return null;
    }
  }

  expandEndpointRefs(epRefs: Readonly<IEndpointRef>[]): Readonly<IEndpoint>[] {
    return _.compact(_.map(epRefs, (ref: IEndpoint) => {
      let ep = this.endpoints.get(ref.rtcId);
      if (ep) {
        return ep;
      }
      return undefined;
    }));
  }

  getEndpointById(rtcId: string): Readonly<IEndpoint> {
    return this.endpoints.get(rtcId);
  }

  getEndpointByUserId(userId: string): Readonly<IEndpoint> {
    if (userId) {
      let found: IEndpoint[] = [...this.endpoints.values()]
        .filter((value: IEndpoint) => {
          return (value?.userId == userId);
        });
      if (found?.length) {
        return found[0];
      }
    }
  }

  /**
   * create a new endpoint record in conference
   * @param endpoint: IEndpoint - endpoint to create
   */
  create(endpoint: IEndpoint): void {
    if (!endpoint) {
      return;
    }
    let matchedEps = _.find(_.map(this.endpoints.entries(), (key, value) => {return value;}), (ep: IEndpoint) => {
      if (endpoint.rtcId) {
        return ep.rtcId === endpoint.rtcId;
      } else if (ep.userId) {
        return ep.userId === endpoint.userId;
      }
    });
    if (!matchedEps) {
      let item = new Endpoint(endpoint.rtcId);
      _.assignIn(item, endpoint);
      this.endpoints.set(endpoint.rtcId, item);
    }
  }

  /**
   * delete endpoint from data
   * @param endpoint: IEndpoint - endpoint to be deleted
   */
  delete(endpoint: IEndpoint): void {
    this.endpoints.delete(endpoint?.rtcId);
  }

  /**
   * delete endpoints by rtcId
   * @param rtcIds: string[] - the rtcIds of endpoints to be deleted
   */
  deleteByIds(rtcIds: string[]): void {
    for (var i = 0; i < rtcIds.length; ++i) {
      this.endpoints.delete(rtcIds[i]);
    }
  }

  /**
   * create endpoints by rtcId
   * @param rtcIds: string[] - the rtcIds of endpoints to be created
   */
  createByIds(rtcIds: string[]): void {
    for (var i = 0; i < rtcIds.length; ++i) {
      if (rtcIds[i] && rtcIds[i].length > 0) {
        this.create(this.findOrCreateEndpointByRTCId(rtcIds[i]));
      }
    }
  }

  updateStatsById(
    rtcId: string,
    stats: IPCStats
  ) {
    let matchedEp: IEndpoint = this.findEndpoint(rtcId);
    if (matchedEp) {
      // Will clear stats if we are clearing them.
      matchedEp.lastStats = stats;
    }
  }

  /**
   * find or create endpoint by rtcId
   * @param rtcId: string - the rtcId to search for
   */
  findOrCreateEndpointByRTCId(
    rtcId: string
  ): IEndpoint {
    let matchedEndpoint: IEndpoint = this.endpoints.get(rtcId);
    if (matchedEndpoint) {
      return matchedEndpoint;
    } else {
      var endpoint: IEndpoint = new Endpoint(rtcId, "");
      this.create(endpoint);
      return endpoint;
    }
  }

  /**
   * enable monitoring on target based on curreng monitoring state.
   * @param epToBeMonitoredRtcId: Endpoint to be monitored
   * @param successCallback
   * @param failedCallback
   */
  startMonitoring(
    epToBeMonitoredRtcId: string,
    successCallback?: (session: IConferenceRef) => void,
    failedCallback?: (error: string) => void): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "start_monitoring",
      {
        epToBeMonitoredRtcId
      },
      (msgType: string, msgData: any) => {
        if (successCallback) {
          successCallback(msgData?.session);
        }
      },
      (errorCode: string, errorText: string) => {
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * disable monitoring on target based on curreng monitoring state.
   * @param monitorSessionId: The monitor session id if turning off or adding
   * @param epToBeMonitoredRtcId: Endpoint to be monitored
   * @param successCallback
   * @param failedCallback
   */
  stopMonitoring(
    monitorSessionId,
    epToBeMonitoredRtcId: string,
    successCallback?: (session: IConferenceRef) => void,
    failedCallback?: (error: string) => void): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "stop_monitoring",
      {
        monitorSessionId,
        epToBeMonitoredRtcId
      },
      (msgType: string, msgData: any) => {
        if (successCallback) {
          successCallback(msgData?.session);
        }
      },
      (errorCode: string, errorText: string) => {
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * place target endpoint on hold
   * @param operatorRtcId: string - Operator id
   * @param guestRtcId: string - The endpoint to edit id
   * @param hold: string - is hold or resume
   * @param successCallback
   * @param failedCallback
   */
  placeEndpointOnHold(
    guestRtcId: string,
    confId: string,
    hold: boolean,
    successCallback?: () => void,
    failedCallback?: (error: string) => void): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "hold_endpoint_in_conference",
      {
        targetId: guestRtcId,
        conferenceId: confId,
        hold : hold
      },
      (msgType: string, msgData: any) => {
        if (successCallback) {
          successCallback();
        }
      },
      (errorCode: string, errorText: string) => {
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * notify endpoints that this endpoint is recording.
   * @param successCallback
   * @param failedCallback
   */
  recordConferenceStarted(
    conferenceId: string,
    successCallback?: () => void,
    failedCallback?: (error: string) => void): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "record_conference_start",{conferenceId: conferenceId},
      (msgType: string, msgData: any) => {
        console.log("notification of record conference success", msgData);
        if (successCallback) {
          successCallback();
        }
      },
      (errorCode: string, errorText: string) => {
        console.log("notification of record conference fail", errorText);
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * notify endpoints that this endpoint is not recording.
   * @param successCallback
   * @param failedCallback
   */
  recordConferenceStopped(
    conferenceId: string,
    successCallback?: () => void,
    failedCallback?: (error: string) => void): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "record_conference_stop", {conferenceId: conferenceId},
      (msgType: string, msgData: any) => {
        if (successCallback) {
          successCallback();
        }
      },
      (errorCode: string, errorText: string) => {
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * add my endpoint to room
   * @param room: IConference - the room to add into
   * @param successHandler?: (roomName: string) => void - handler when succeed
   * @param failureHandler?: (errorCode: string, errorText: string, roomName: string) => void - handler when failed
   */
  addToRoom(
    room: IConferenceBase,
    successHandler?: (roomName: string) => void,
    failureHandler?: (errorCode: string, errorText: string, roomName: string) => void
  ): void {
    if (room) {
      const userId = this.userService.currentUser["_id"];
      EasyRTCService.getSharedInstance().sendServerMessage(
        "join_room",
        {
          name: room.name,
          groupId: room.groupId,
          type: RoomType[room.roomType],
          userId: userId
        },
        (msgType: string, msgData: any) => {
          this.conferenceService.alertHandler(AlertCode.joinRoomSuccess, `userId: ${userId} => room: ${room.name}`, AlertLevel.success);
          let joinedRoom : IConferenceRef = msgData.room;
          // Instantiate the shell of the conference room in our local storage.
          if (joinedRoom.roomType === RoomType.GuestWaitRoom) {
            ConferenceService.getSharedInstance().setQueueInfo(joinedRoom as IQueueConference);
          } else {
            ConferenceService.getSharedInstance().setActiveInfo(joinedRoom as IActiveConference);
          }
          this.clearJoinRoomTimer(joinedRoom.name);
          if (successHandler) {
            successHandler(joinedRoom.name);
          }
        },
        (errorCode: string, errorText: string) => {
          this.conferenceService.alertHandler(AlertCode.joinRoomFail, `userId: ${userId} error: ${errorCode}, ${errorText}`);
          if (failureHandler) {
            failureHandler(errorCode, errorText, room.name);
          }
        }
      );
      this.clearJoinRoomTimer(room.name);
      this.joinTimerObj[room.name] = setTimeout(() => {
        this.conferenceService.alertHandler(AlertCode.joinRoomRetry, `roomName: ${room.name}`);
        this.addToRoom(room, successHandler, failureHandler);
      }, JOIN_ACK_TIMEOUT);
    }
  }

  /**
   * invalidate join_room timer for a specific room
   * @param roomName
   */
  clearJoinRoomTimer(roomName: string) {
    clearTimeout(this.joinTimerObj[roomName]);
    delete this.joinTimerObj[roomName];
  }

  clearTimers(): void {
    let roomJoins = Object.keys(this.joinTimerObj);
    _.forEach(roomJoins, (room) => {
      this.clearJoinRoomTimer(room);
    });
  }

  /**
   * remove my endpoint from room
   * @param room: IConference - the room to remove from
   * @param successHandler?: (roomName: string) => void - handler when succeed
   * @param failureHandler?: (errorCode: string, errorText: string, roomName: string) => void - handler when failed
   */
  removeFromRoom(
    room: IConferenceBase,
    successHandler?: (roomName: string) => void,
    failureHandler?: (errorCode: string, errorText: string, roomName: string) => void
  ): void {
    if (room) {
      this.clearJoinRoomTimer(room.name);
      const userId = this.userService.currentUser["_id"];
      EasyRTCService.getSharedInstance().sendServerMessage(
        "leave_room",
        {
          name: room.name,
          groupId: room.groupId,
          type: RoomType[room.roomType],
          userId: userId
        },
        (msgType: string, msgData: any) => {
          this.conferenceService.alertHandler(AlertCode.leaveRoomSuccess, `userId: ${userId} => room: ${room.name}`);
          // Scrub from ready transition check map
          this.readyTransitionTriggerMap.delete(room.name);
          successHandler(room.name);
        },
        (errorCode: string, errorText: string) => {
          this.conferenceService.alertHandler(AlertCode.leaveRoomFail, `userId: ${userId} error: ${errorCode}, ${errorText}`);
          failureHandler(errorCode, errorText, room.name);
        }
      );
    }
  }

  /**
   * remove my endpoint from room promise
   */
  removeFromRoomPromise(room: IConferenceBase): Promise<void> {
    return new Promise((resolve: () => void, reject: (error: Error) => void) => {
      this.removeFromRoom(room, resolve.bind(this), reject.bind(this, new Error("LEAVE_ROOM_FAILED")));
    });
  }

  /**
   * send update audio device to server
   */
  sendUpdateVoiceAudioDeviceToServer(rtcId: string) {
    EasyRTCService.getSharedInstance().sendServerMessage("update_voice_audio_device", {});
  }

  /**
   * send invitation to an endpoint to connect another endpoint
   * @param inviteeRtcId: string - the endpoint to invite
   * @param callerId: string - the caller
   * @param voiceEpId: string
   * @param targetRtcIds: string[] - the guests to connect
   * @param isTransfer: boolean - this parameter indicate that the invitation is due to a call transfer, default is false
   * @param isResumeGuestConference: boolean - the invitation is from a resume guest conference
   * @param isTakeover: boolean - the invitation is from a takeover
   * @param connectionType - type of connection ('inbound'|'outbound'|'internal')
   * @param successCallback
   * @param failedCallback
   */
  sendInvitationToConnect(
    inviteeRtcId: string,
    confId: string,
    successCallback: any,
    failedCallback: any
  ): void {
    let disconnectTimer = null;

    EasyRTCService.getSharedInstance().sendServerMessage("invite_to_connect", {
      inviteeRtcId : inviteeRtcId,
      conferenceId : confId,
    }, (msgType: string, msgData: any) => {
      if (successCallback) {
        clearTimeout(disconnectTimer);
        successCallback();
      }
    }, (errorCode: string, errorText: string) => {
      if (failedCallback) {
        this.conferenceService.alertHandler(AlertCode.sendInvitationFail, `Send invitation Fail`);
        clearTimeout(disconnectTimer);
        failedCallback(errorText);
      }
    });
  }

  /**
   * send reject to answer an endpoint in the queue.
   * @param endpoint: IEndpoint - the endpoint to rejects
   * @param ready: transition to ready after rejecting? (we go unanavailble if not)
   */
  sendRejectToAnswer(
    endpoint: IEndpoint,
    successCallback?: any, 
    failedCallback?: any,
  ): void {
    EasyRTCService.getSharedInstance().sendServerMessage("reject_queue_answer", {
      epRtcId: endpoint.rtcId,
    }, () => 
    {
      if (successCallback) {
        successCallback();
      }
    }, 
    (errorCode: string, errorText: string) =>
    {
      if (failedCallback) {
        failedCallback(errorCode, errorText);
      }
    });
  }

  /**
   * cancel active call call
   * @param successCallback
   * @param failedCallback
   * @param isTransfer
   */
  cancelCall(successCallback: () => void, failedCallback: (error: string) => void, isTransfer = false): void {
    EasyRTCService.getSharedInstance().sendServerMessage(
      "rescind_active_invitation",
      {}, // No data required.
      (msgType: string, msgData: any) => {
        if (successCallback) {
          successCallback();
        }
      },
      (errorCode: string, errorText: string) => {
        if (failedCallback) {
          failedCallback(errorText);
        }
      }
    );
  }

  /**
   * transfer an endpoint to a different queue,
   * @param endpoint: IEndpoint - the endpoint to send
   * @param queueName: name - The Queue to send to
   */
  transferToQueue(endpoint: IEndpoint, queueName: string): void {
    // get queue name from skill tags
    EasyRTCService.getSharedInstance().sendServerMessage(
      "transfer_to_queue", { commandName: "transfer_to_queue", 
      targetId: endpoint.rtcId, queueName: queueName, conferenceId: this.conferenceService.currentActiveConference?.id });
  }

  /**
   * Attempt to transfer an endpoint directly to a another operater
   * @param guestEndpoint: IEndpoint - the endpoint to trasnsfer
   * @param targetAgent: IEndpoint - the agent to transfer to.
   */
  transferToOperator(guestEndpoint: IEndpoint, targetAgent: IEndpoint): void 
  {    
    const guestId = guestEndpoint.rtcId; // the guest to transfer.
    const targetAgentId = targetAgent.rtcId; // the target agent to transfer to.
    const conferenceId = this.conferenceService.currentActiveConference?.id; // the current conference guest and originator reside in.
    // get queue name from skill tags
    EasyRTCService.getSharedInstance().sendServerMessage(
      "ask_to_transfer", 
      { targetAgentId: targetAgentId, guestId: guestId, conferenceId: conferenceId });
  }

  /**
   * request to send logs
   * @param rtcId
   * @param result
   */
  sendRequestToSendLogs(rtcId: string, result?: any) {
    EasyRTCService.getSharedInstance().sendServerMessage("request_to_send_logs",
      { targetRtcId: rtcId, result: result });
  }

  /**
   * update endpoint streams according to receivers
   */
  updateEndpointStreamsAccordingToReceivers(rtcId: string) {
    let endpoint = this.endpoints.get(rtcId);
    if (!endpoint) {
      return;
    }
    let pc = EasyRTCService.getSharedInstance().getPeerConnectionById(endpoint.rtcId);
    if (!pc) {
      return;
    }
    let stream;
    let receivers: RTCRtpReceiver[] = pc?.getReceivers();
    let tracks: MediaStreamTrack[] = _.map(receivers, (r) => r.track);
    let audioTracks: MediaStreamTrack[] = _.filter(tracks, {kind: "audio", muted: false}) as MediaStreamTrack[];
    let videoTracks: MediaStreamTrack[] = _.filter(tracks, {kind: "video", muted: false}) as MediaStreamTrack[];
    let streams : MediaStream[] = [];
    _.forEach(videoTracks, (track: MediaStreamTrack, index: number) => {
      stream = new MediaStream();
      stream.addTrack(track);
      let contentType = EasyRTCService.getSharedInstance().getContentType(endpoint.rtcId, track.id);
      if (contentType) {
        switch (contentType) {
          case "content:slides": stream.streamName = "screen"; break;
          case "content:main": stream.streamName = "camera"; break;
          default: stream.streamName = "camera"; break;
        }
      } else {
        stream.streamName = index === 0 ? "camera" : "screen";
      }
      streams.push(stream);
    });
    _.forEach(audioTracks, (track: MediaStreamTrack, index: number) => {
      if (streams[index]) {
        streams[index].addTrack(track);
      } else {
        stream = new MediaStream();
        stream.addTrack(track);
        stream.streamName = index === 0 ? "camera" : "screen";
        streams.push(stream);
      }
    });
    console.log("Streams Registered By Receiver: ", streams);
    endpoint.streams = _.keyBy(streams, "id");
    console.log("Streams Registered into EP: ", endpoint.streams);
  }

  /**
   * update my endpoint streams from rtc service
   */
  updateMyEndpointStreams() {
    let rtcServiceStreamIds: string[] = _.keys(EasyRTCService.getSharedInstance().rtcClient.defaultStreams);
    let myEndpointStreamIds: string[] = _.keys(this.myEndpoint.streams);
    _.forEach(_.difference(myEndpointStreamIds, rtcServiceStreamIds), (streamId: string) => {
      delete this.myEndpoint.streams[streamId];
    });
    _.forEach(_.difference(rtcServiceStreamIds, myEndpointStreamIds), (streamId: string) => {
      let stream: MediaStream = EasyRTCService.getSharedInstance().rtcClient.defaultStreams[streamId];
      this.myEndpoint.streams[streamId] = stream;
    });
  }

  /**
   * remove all streams of endpoint
   */
  removeAllStreamsOfEndpoint(rtcId: string) {
    var endpoint: IEndpoint = this.endpoints.get(rtcId);
    if (endpoint) {
      endpoint.streams = {};
    }
  }

  /**
   * update volume on the video element of endpoint
   * @param endpoint: IEndpoint - the endpoint of video to update
   * @param videoElement: HTMLVideoElement - the video element of endpoint
   * @param isMuted: boolean - if endpoint should be muted entirely
   * @param volume: number - the current volume to be set
   */
  updateEndpointVolume(endpoint: IEndpoint, isMuted: boolean, volume: number, videoElement?: HTMLVideoElement) {
    console.log(`setting ${endpoint.rtcId}:${endpoint.name} muted: ${isMuted} with vol: ${volume}`);
    endpoint.volume = volume;
    if (videoElement) {
      videoElement.volume = volume;
      videoElement.muted = isMuted;
    }
  }

  /**
   * get transmit mode to endpoint by rtcId
   */
  getTransmitModeToEndpointByRtcId(rtcId: string): VideoMediaConnectionMode {
    let ep: IEndpoint = this.endpoints.get(rtcId);
    if (ep) {
      return ep.transmitMode;
    }
    return null;
  }

  /**
   * set transmit mode to endpoint by rtcId
   */
  setTransmitModeToEndpointByRtcId(rtcId: string, mode: VideoMediaConnectionMode): void {
    let ep: IEndpoint = this.findOrCreateEndpointByRTCId(rtcId);
    if (ep) {
      ep.transmitMode = mode;
    }
  }

  /**
   * get current geolocation
   */
  getGeoLocation(): Promise<ICoordinates> {
    return new Promise((resolve: (result: ICoordinates) => void, reject: (error: Error) => void) => {
      if (navigator.geolocation) {
        this.myEndpoint.geoAccessStatus = BrowserResourceAccessEvent.requesting;
        let geolocationWatch = navigator.geolocation.watchPosition(
          (geolocation: any) => {
            if (!geolocation || !geolocation.coords || !geolocation.coords.latitude) {
              return;
            }
            if (this.myEndpoint.geoAccessStatus !== BrowserResourceAccessEvent.accessed) {
              this.conferenceService.alertHandler(AlertCode.geoAccessSuccess, "Geolocation Found", AlertLevel.success);
            }
            this.myEndpoint.geoAccessStatus = BrowserResourceAccessEvent.accessed;
            this.myEndpoint.location = geolocation.coords;
            resolve(this.myEndpoint.location);
          },
          (error: any) => {
            this.myEndpoint.geoAccessStatus = BrowserResourceAccessEvent.failed;
            this.conferenceService.alertHandler(
              AlertCode.geoAccessFail,
              error ? error.message : undefined,
              AlertLevel.warning
            );
            $("#allowGeolocation").prop("checked", false);
            navigator.geolocation.clearWatch(geolocationWatch);
            reject(error);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      } else {
        this.conferenceService.alertHandler(
          AlertCode.geoAccessNotPermit,
          "Geolocation is not supported by this browser.",
          AlertLevel.error
        );
        reject(new Error("GEO_ACCESS_NOT_PERMIT"));
      }
    });
  }

  /**
   * send reload command to client
   * @param rtcId: string - client session id
   */
  sendReloadCommandToClient(rtcId: string) {
    EasyRTCService.getSharedInstance().sendServerMessage("reload_client", {
      targetRtcId: rtcId
    });
  }

  /**
   * send switch_camera request to endpoint
   */
  sendSwitchCameraRequest(endpoint: IEndpoint) {
    EasyRTCService.getSharedInstance().sendServerMessage("switch_camera", {
      targetRtcId: endpoint.rtcId
    });
  }

  /**
   * send ask to answer
   * @param operatorEp
   * @param guestEp
   */
  sendConfirmAskToAnswer(operatorEp: IEndpoint, guestEp: IEndpoint) {
    EasyRTCService.getSharedInstance().sendServerMessage("ask_to_answer_ok", {
      operatorRtcId: operatorEp.rtcId,
      guestRtcId: guestEp.rtcId
    });
  }

  /**
   * send the confirmation if we wish to accept the transfer.
   * @param accept the transfer (false if rejects)
   * @param guest the guest to accept
   * @param conference the new conference to use for the call
   */
  sendConfirmTransfer(accept: boolean, guestEp: IEndpointRef, conference : IConferenceRef ) {
    EasyRTCService.getSharedInstance().sendServerMessage("confirm_answer_transfer", {
      guestId: guestEp.rtcId,
      accept: accept, 
      conferenceId: conference ? conference.id : null
    });
  }

  /**
   * Accept invitation to connect
   */
  acceptInvitation(inviterRtcId: string, confId: string, accept: boolean, successCallback?: any, failedCallback?: any)
  {
    EasyRTCService.getSharedInstance().sendServerMessage("invitation_response", {
      inviterRtcId: inviterRtcId,
      conferenceId: confId,
      accept: accept
    }, (msgType: string, msgData: any) => {
      // set ourselves to available
      this.myEndpoint.status = msgData?.status || PresenceStatus.available;
      console.log("Processed invitation_response callback.");
    });
  }

  /**
   * If an endpoint is operator or specialist
   */
  isOperator(endpoint: IEndpoint): boolean {
    // just return if the EP has a user ID.
    return !!endpoint.userId;
  }

  /**
   * If an endpoint is connected to an active conference
   */
  connected(endpoint: IEndpoint): boolean {
    return !!this.conferenceService.getConferenceFromEndpoint(endpoint);
  }

  /**
   * If my endpoint is connected to a sub conference
   */
  myEpConnected(): boolean {
    return !!this.conferenceService.getConferenceFromEndpoint(this.myEndpoint);
  }
  /**
   * If my endpoint is connected in a conference it doesn't own
   */
  myEpConnectedAsGuest(): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(this.myEndpoint);
    return activeConf && activeConf.ownerId !== this.myEndpoint.userId;
  }

   /**
   * Check if a conference the endpoint is in, is mine.
   */
  inMyConf(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
      (activeConf.ownerId === this.myEndpoint.userId);
  }

  /**
   * If an endpoint is connected to me (master ep) in a conference
   * that I own. And i am participating in that conference
   */
  connectedWithMeInMyConf(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
      (activeConf.ownerId === this.myEndpoint.userId) &&
      _.some(activeConf.everyone, (ep : IEndpointRef) => { return (ep.rtcId === this.myEndpoint.rtcId)});;
  }
  /**
   * If an endpoint is actively connected to me in the same conference
   */
  connectedToMe(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
      _.some(activeConf.active, (ep : IEndpointRef) => { return (ep.rtcId === this.myEndpoint.rtcId)}) &&
      _.some(activeConf.active, (ep : IEndpointRef) => { return (ep.rtcId === endpoint.rtcId)});
  }

  /**
   * If an endpoint is connected to me in the same conference
   */
  connectedToSameConf(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
      _.some(activeConf.everyone, (ep : IEndpointRef) => { return (ep.rtcId === this.myEndpoint.rtcId)}) &&
      _.some(activeConf.everyone, (ep : IEndpointRef) => { return (ep.rtcId === endpoint.rtcId)});
  }

  /**
   * If an endpoint is in a monitoring conference and that endpoint is not me.
   * We want to be able to toggle monitoring or whisper to enpoints in the list that 
   * we know there is a monitor session for but we don't want to put the buttons on ourself.
   */
  monitoring(endpoint: IEndpoint): boolean {
    let monConf = this.conferenceService.getMonitorFromEndpoint(endpoint);
    // TODO: Update when you can join a monitor session that you don't own.
    if (monConf && monConf.ownerId != endpoint.userId) {
      return true;
    }
    return false;
  }

  /**
   * If an endpoint is on hold
   */
  inHold(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && _.some((activeConf.held), (epToCheck : IEndpointRef) => { return endpoint.rtcId === epToCheck.rtcId});
  }

  /**
   * If an endpoint in my conference is on hold
   */
  inHoldWithMeInMyConf(endpoint: IEndpoint): boolean {
    return (this.inHold(endpoint) &&
    this.connectedWithMeInMyConf(endpoint));
  }

  /**
   * If an endpoint is in a conference with me and on hold
   */
  onHoldToMe(endpoint: IEndpoint): boolean {
    return (this.inHold(endpoint) &&
    this.connectedToSameConf(endpoint));
  }

  /**
   * If an endpoint is in my conference and on hold
   */
  onHoldInMyConf(endpoint: IEndpoint): boolean {
    return (this.inHold(endpoint) &&
    this.inMyConf(endpoint));
  }

  /**
   * An endpoint is available for take over if I am not the conference owner
   * and the endpoint's conference owner is absent from the confernce.
   */
  availableForTakeOver(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
           (activeConf.ownerId !== this.myEndpoint.userId) &&
           !_.some(activeConf.everyone, (ep : IEndpointRef) => { return (ep.userId === activeConf.ownerId)});
  }

  
  /**
   * Is the endpoint alone in conf
   */
  isAloneInConf(endpoint: IEndpoint): boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
           (activeConf.active?.length == 1) &&
           _.some(activeConf.active, (ep : IEndpointRef) => { return (ep.rtcId === endpoint.rtcId)});
  }

  amISteppedAway(endpoint: IEndpoint) : boolean {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    return activeConf && 
           activeConf.ownerId == this.myEndpoint.userId && 
           !_.some(activeConf.everyone, (ep) => { return (ep.rtcId === this.myEndpoint.rtcId)})
  }

  /**
   * update endpoint's data on the server.
   */
  sendEndpointUpdate()
  {
    EasyRTCService.getSharedInstance().sendServerMessage("update_endpoint", { 
      message: this.myEndpoint.lastMessage,
      theme: this.myEndpoint.theme,
      name: this.myEndpoint.name,
      location: this.myEndpoint.location,
      language: this.myEndpoint.language,
      cameraRotation: this.myEndpoint.cameraRotation,
      phoneNumber: this.myEndpoint.phoneNumber,
      displayPhoneNumber: this.myEndpoint.displayPhoneNumber,
      skillSet: this.myEndpoint.skillSet,
      remoteId: this.myEndpoint.remoteIdentity,
      deviceInfo: this.myEndpoint.deviceInfo,
      cameraPermission: this.myEndpoint.cameraPermission,
      microphonePermission: this.myEndpoint.microphonePermission,
      lastMessage: this.myEndpoint.lastMessage,
      guestFormData: this.myEndpoint.guestFormData,
      isAudioMuted: this.myEndpoint.isAudioMuted,
      isVideoMuted: this.myEndpoint.isVideoMuted
    } as EndpointUpdate);
  }

  /**
   * remove an endpoint from a conference
   */
  removeFromConference(targetRtcId: string, confId: string)
  {
    EasyRTCService.getSharedInstance().sendServerMessage("remove_from_conference", {
      commandName: "remove_from_conference", targetRtcId: targetRtcId, conferenceId : confId, }, () =>
        {
          this.conferenceService.alertHandler(AlertCode.disconnectFromConferenceSuccess, 
            `${targetRtcId} removed from conf ${confId}`, AlertLevel.info);
        },
        () => {
          this.conferenceService.alertHandler(AlertCode.disconnectFromConferenceFail, 
            `${targetRtcId} remove from conference failed  ${confId}`, AlertLevel.error);
        });
  }

  
  /**
   * add an endpoint into an active conference. (currnently only supports adding
   * yourself to a conference you own.)
   */
  addIntoConference(targetRtcId: string, confId: string)
  {
    EasyRTCService.getSharedInstance().sendServerMessage("add_to_conference", {
      commandName: "add_to_conference", targetRtcId: targetRtcId, conferenceId : confId}, () =>
    {
      // if we are adding ourself some where, we should go, connecting while we 
      // enter into the conference.
      if(targetRtcId == this.myEndpoint.rtcId)
      {
        this.myEndpoint.status = PresenceStatus.connecting;
      }
      this.conferenceService.alertHandler(AlertCode.addToConferenceSuccess, `${confId}`, AlertLevel.info);
    },
    (errorCode, errorString) => {
      this.conferenceService.alertHandler(AlertCode.addToConferenceFail, `${errorCode}: ${errorString}`, AlertLevel.prominent, {seconds: 3});
    });
  }

  /**
   * inform the server we are going "ready"
   */
  markClientReady() : Promise<void>
  {
    console.log("Mark Client Ready");
    return new Promise((resolve: (result : void) => void, reject: (reason: string) => void) => {  
      EasyRTCService.getSharedInstance().sendServerMessage("mark_ready", {
      commandName: "mark_ready"}, () => {
        // set ourselves to available
        this.myEndpoint.status = PresenceStatus.available;
        console.log("Mark Client Ready success");
        resolve()
      },
      () => {
        // what do we do if this fails?
        console.log("Mark Client Ready failure!");
        reject("Mark Client Ready failure!")
      });
    });
  }

  /**
   * inform the server we are going to be unavailable
   */
  markClientUnready(reason : StatusReason) 
  {
    console.log("Mark Client AWAY", StatusReason[reason]);
    EasyRTCService.getSharedInstance().sendServerMessage("mark_unready",
      {commandName: "mark_unready", reason},
      () => {
        let status =  PresenceStatus.away;
        switch(reason)
        {
          case StatusReason.away:
            status = PresenceStatus.away;
            break;
          case StatusReason.invisible:
            status = PresenceStatus.invisible;
            break;
          case StatusReason.recess:
            status = PresenceStatus.ready;
            break;
          case StatusReason.custom1:
            status = PresenceStatus.custom1;
            break;
          case StatusReason.custom2:
            status = PresenceStatus.custom2;
            break;
          case StatusReason.custom3:
            status = PresenceStatus.custom3;
            break;
          case StatusReason.custom4:
            status = PresenceStatus.custom4;
            break;
          case StatusReason.work:
            status = PresenceStatus.work;
            break;
        }
        this.myEndpoint.status = status;
        if (reason) 
          this.myEndpoint.statusReason = reason;
        console.log("Mark Client Unready success");
      },
      () => {
        // what do we do if this fails?
        console.log("Mark Client Unready failure!");
      });
  }

  /**
   * infrom the server that we are going offline
   */
  markClientGone() : Promise<void> {
    return new Promise<void>((resolve, reject) => {
      EasyRTCService.getSharedInstance().sendServerMessage("mark_gone",
        {commandName: "mark_gone" },
        (msgType, msgData) => {
          console.log("Mark Client Gone success.");
          // for gone we don't care what the server says, we are out of here.
          EndpointService.getSharedInstance().myEndpoint.status = PresenceStatus.offline;
          resolve();
        },
        (errorCode, errorText) => {
          reject(errorText);
        });
    });
  }


  /**
   * inform the server we are waiting for an agent to answer us
   */
  awaitInvitation()  : Promise<void>
  {
    console.log("Requst Await Invitation");
    return new Promise((resolve: (result : void) => void, reject: (reason: string) => void) => { 
      EasyRTCService.getSharedInstance().sendServerMessage("await_invitation", {
      commandName: "await_invitation"}, () => {
        // set ourselves to available
        this.myEndpoint.status = PresenceStatus.connecting;
        console.log("Await Invitation success");
        resolve();
      } ,
      () => {
        console.log("Await Invitation failed");
        reject("await invite failed");
      });
    });
  }

  /**
   * Take over a conference (change it's ownership) and join it
   */
  sendTakeOverConference(endpoint : IEndpointRef)
  {
    let activeConf = this.conferenceService.getConferenceFromEndpoint(endpoint);

    if(!activeConf)
    {
      return;
    }

    EasyRTCService.getSharedInstance().sendServerMessage("take_over_conference", {
      commandName: "take_over_conference", conferenceId : activeConf?.id}, () =>
    {
      // set ourselves to connecting when we attempt to take over a conference.
      this.myEndpoint.status = PresenceStatus.connecting;
    });
  }

  /**
   * Send callback request to the server to turn this guest endpoint into a callback endpoint
   * @param phoneNumber the phone digits to dial for the callback
   * @param displayPhoneNumber the human readable number (with symbols and spaces)
   */
  sendCallbackRequest(phoneNumber : string, displayPhoneNumber : string) : Promise<void>
  {
    return new Promise((resolve: (result : void) => void, reject: (reason: string) => void) => {
      EasyRTCService.getSharedInstance().sendServerMessage("callback_submitted", {
        commandName: "callback_submitted", phoneNumber : phoneNumber, displayPhoneNumber: displayPhoneNumber}, () =>
      {
        // set ourselves to calback
        this.myEndpoint.status = PresenceStatus.callback;
        resolve();
      }, 
      () => {
        reject("callback submission failed");
      });
    });
  }

  /**
   * handle a callback call externally (don't use the voice service)
   */
  sendHandleCallbackExternally(guestId : string, confId : string) : Promise<void>
  {
    return new Promise((resolve: (result : void) => void, reject: (reason: string) => void) => {
      EasyRTCService.getSharedInstance().sendServerMessage("handle_callback_external", {
        commandName: "handle_callback_external", guestId : guestId, confId : confId}, () =>
      {
        // set ourselves to connected as this goes immediately connected.
        this.myEndpoint.status = PresenceStatus.busy;
        resolve();
      }, 
      () => {
        reject("callback external answer failed");
      });
    });    
  }
}
