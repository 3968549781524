<!--
    Copyright Compunetix Incorporated 2018-2024
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<ng-template #tooltipTemplate><div [innerHtml]="parameter.tooltip | safeHtml"></div></ng-template>
<div
  [formGroup]="form"
  [hidden]="!isSectionToggler && !isSectionEnabled || parameter.isHidden"
  [ngClass]="{'inline-block': parameter.displayInLine}"
>

  <label
    [htmlFor]="controlKey"
    class="text-bold"
    *ngIf="parameter.title && parameter.type !== 'checkbox' && parameter.type !== 'hidden' && parameter.type !== 'header'"
  >
    {{parameter.title}}
  </label><span *ngIf="parameter.tooltip"><i data-html="true" [tooltip]="tooltipTemplate" class="fa fa-info-circle" style="color:#007bff;margin-left: 5px"></i></span>
  <p *ngIf="parameter.type === 'label' && parameter.value">
    {{parameter.value}}
  </p>
  <h4 *ngIf="parameter.type === 'header'">
    {{parameter.title}}
  </h4>
  <hr *ngIf="parameter.type === 'header'">

  <div
    [ngSwitch]="parameter.type"
    class="form-group"
  >

    <input
      *ngSwitchCase="'number'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      [id]="controlKey"
      type="number"
      (change)="valueChanged($event)"
      #InputField
      autofocus
    >
    <input
      *ngSwitchCase="'string'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      [id]="controlKey"
      type="text"
      pattern="{{parameter.pattern}}"
      (change)="valueChanged($event)"
      #InputField
      autofocus
    >
    <textarea
      *ngSwitchCase="'textarea'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      [id]="controlKey"
      rows="4" cols="50"
      (change)="valueChanged($event)"
      #InputField
      autofocus
    ></textarea>

    <div
      class="input-group"
      *ngSwitchCase="'password'"
    >
      <input
        [formControlName]="controlKey"
        class="form-control"
        [attr.disabled]="parameter.disabled"
        [ngClass]="{'disabled': parameter.disabled}"
        [id]="controlKey"
        type="password"
        (change)="valueChanged($event)"
        #InputField
        autofocus
        pattern="{{parameter.pattern}}"
      >
      <button
        *ngIf="controlKey === 'user_account_password' && isEditing"
        class="btn btn-outline-light"
        (click)="enablePasswordField(!passwordFieldEnabled)"
        style="color:#007bff;margin-left: 5px"
      ><i [ngClass]="passwordFieldEnabled ? 'fa fa-lock-open' : 'fa fa-lock'"></i></button>
    </div>

    <input
      *ngSwitchCase="'email'"
      [formControlName]="controlKey"
      [id]="controlKey"
      type="email"
      class="form-control"
      (change)="valueChanged($event)"
      #InputField
      autofocus
      pattern="{{parameter.pattern}}"
    >

    <input
      *ngSwitchCase="'phone'"
      [formControlName]="controlKey"
      [id]="controlKey"
      type="tel"
      class="form-control"
      (change)="valueChanged($event)"
      #InputField
      autofocus
      pattern="{{parameter.pattern}}"
    >

    <div
      class="input-group"
      *ngSwitchCase="'date'"
    >
      <input
        [formControlName]="controlKey"
        class="form-control"
        [disabled]="parameter.disabled"
        [ngClass]="{'disabled': parameter.disabled}"
        [id]="controlKey"
        type="text"
        (change)="valueChanged($event)"
        autofocus
        bsDatepicker
        #dp="bsDatepicker"
        [bsConfig]="{ dateInputFormat: 'yyyy-MM-dd' }"
      >
      <div class="input-group-btn" [hidden]="parameter.disabled">
        <button
          class="btn btn-secondary"
          (click)="dp.show()"
          [attr.aria-expanded]="dp.isOpen"
          type="button"
          enabled="!parameter.disabled"
        >
          <i class="fas fa-calendar-alt"></i>
        </button>
      </div>
    </div>

    <input
      *ngSwitchCase="'time'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      [id]="controlKey"
      [type]="parameter.type"
      (change)="valueChanged($event)"
      #InputField
      autofocus
    >

    <select
      [id]="controlKey"
      *ngSwitchCase="'dropdown'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      (change)="valueChanged($event)"
    >
      <option
        *ngFor="let key of parameter.options | keys"
        [value]="key"
      >
        {{parameter.options[key]}}
      </option>
    </select>
    <div
      *ngSwitchCase="'auto_complete_list'"
      [formArrayName]="controlKey"
    >
      <div
        *ngFor="let listItem of form.controls[controlKey].controls; index as i"
        class="row"
      >
        <div class="col-md-2">
          {{(i+1) + ': '}}
        </div>
        TODO IMPLEMENTATION HERE!

        <div class="col-md-2">
          <a
            class="cursor-pointer text-danger"
            tabindex
            (click)="removeListItem(i)"
          >
            <i class="fas fa-minus-circle"></i>
          </a>
        </div>
      </div>
      <p>
        <a
          class="cursor-pointer"
          tabindex
          (click)="addListItemControl()"
        >
          Add New
          <i class="fas fa-plus-circle"></i>
        </a>
      </p>
    </div>

    <label
      *ngSwitchCase="'checkbox'"
      class="checkbox-button-theme"
      [htmlFor]="controlKey"
      style="margin-top: 1em;"
    >
      <input
        type="checkbox"
        class="checkbox-10"
        [formControlName]="controlKey"
        [id]="controlKey"
        (change)="valueChanged($event)"
      />
      <label
        class="checkbox-button"
        [htmlFor]="controlKey"
        style="margin-bottom: -0.25em;"
      ></label>
      {{parameter.title}}
    </label>

    <select
      [id]="controlKey"
      *ngSwitchCase="'guest_host_feature_toggle'"
      [formControlName]="controlKey"
      class="form-control"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      (change)="valueChanged($event)"
    >
      <option value="">Disabled</option>
      <option value="host-only">Enabled on Host Side Only</option>
      <option value="guest-only">Enabled on Guest Side Only</option>
      <option value="both">Enabled on Both Sides</option>
    </select>

    <input
      *ngSwitchCase="'color'"
      [formControlName]="controlKey"
      [id]="controlKey"
      type="text"
      pattern="{{parameter.pattern}}"
      [(colorPicker)]="form.value[controlKey]"
      [style.background]="form.value[controlKey]"
      [value]="form.value[controlKey]"
      [cpOKButton]="true"
      [cpOKButtonClass]="'btn btn-primary btn-sm'"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'btn btn-sm'"
      [cpOutputFormat]="'rgba'"
      [cpAlphaChannel]="'enabled'"
      (colorPickerChange)="colorPickerChanged($event)"
      [disabled]="parameter.disabled"
      [ngClass]="{'disabled': parameter.disabled}"
      (change)="valueChanged($event)"
    >
    <span *ngSwitchCase="'color'">
      {{form.value[controlKey]}}
    </span>

    <div
      *ngSwitchCase="'theme_package'"
      class="row"
      style="max-width:600px;"
    >
      <div class="col-md-6">
        <input
          [formControlName]="controlKey"
          class="form-control"
          [disabled]="parameter.disabled"
          [ngClass]="{'disabled': parameter.disabled}"
          [id]="controlKey"
          type="text"
        >
        <input
          type="file"
          (change)="uploadThemePackage($event)"
          placeholder="Upload new theme package"
          accept=".tar"
        >
      </div>
    </div>

    <div
      *ngSwitchCase="'video'"
      class="row"
      style="max-width: 600px; overflow: auto;"
    >
      <div
        class="col-md-6"
        *ngFor="let videoSrc of [form.value[controlKey]]"
      >
        <video
          autobuffer
          autoloop
          loop
          controls
          style="max-width: 100%;"
          crossorigin="anonymous"
        >
          <source [attr.src]="videoSrc" />
        </video>
      </div>
      <div class="col-md-6">
        <input
          [formControlName]="controlKey"
          class="form-control"
          [disabled]="parameter.disabled"
          [ngClass]="{'disabled': parameter.disabled}"
          [id]="controlKey"
          type="text"
          pattern="{{parameter.pattern}}"
          (change)="valueChanged($event)"
        >
        <input
          type="file"
          (change)="uploadVideo($event)"
          placeholder="Upload new video"
          accept=".mp3,.wav,.oga,.mp4,.ogg,.ogv,.webm"
        >
        <button
          *ngIf="form.value[controlKey]"
          type="button"
          (click)="removeVideo()"
          class="btn btn-danger"
        >Remove Video</button>
      </div>
    </div>
    <div
      *ngSwitchCase="'image'"
      class="row"
      style="max-width: 600px; overflow: auto;"
    >
      <div
        class="col-md-6"
        *ngIf="form.value[controlKey]"
      >
        <img
          [attr.src]="form.value[controlKey]"
          crossorigin="anonymous"
          alt="{{parameter.title}}"
          style="background-color: #fff"
        >
        <br>white background
      </div>
      <div
        class="col-md-6"
        *ngIf="form.value[controlKey]"
      >
        <img
          [attr.src]="form.value[controlKey]"
          crossorigin="anonymous"
          alt="{{parameter.title}}"
          style="background-color: #000"
        >
        <br>black background
      </div>
      <div class="col-md-6">
        <input
          [formControlName]="controlKey"
          class="form-control"
          [disabled]="parameter.disabled"
          [ngClass]="{'disabled': parameter.disabled}"
          [id]="controlKey"
          type="text"
          pattern="{{parameter.pattern}}"
          (change)="valueChanged($event)"
        >
        <input
          type="file"
          (change)="uploadImage($event)"
          placeholder="Upload new image"
          accept=".jpg,.jpeg,.png,.gif"
        >
        <button
          *ngIf="form.value[controlKey]"
          type="button"
          (click)="removeImage()"
          class="btn btn-danger"
        >Remove Image</button>
      </div>
    </div>
    <div
      *ngSwitchCase="'list'"
      [formArrayName]="controlKey"
    >
      <div
        *ngFor="let listItem of form.controls[controlKey].controls; index as i"
        class="row"
      >
        <div class="col-md-2">
          {{(i+1) + ': '}}
        </div>
        <input
          [formControlName]="i"
          class="form-control col-md-8"
          [id]="controlKey+'_'+i"
          type="text"
          pattern="{{parameter.pattern}}"
          (change)="valueChanged($event)"
          (keydown)="onKeydown($event)"
        >
        <div class="col-md-2">
          <a
            class="cursor-pointer text-danger"
            tabindex
            (click)="removeListItem(i)"
          >
            <i class="fas fa-minus-circle"></i>
          </a>
        </div>
      </div>
      <p>
        <a
          class="cursor-pointer"
          tabindex
          (click)="addListItemControl()"
        >
          Add New
          <i class="fas fa-plus-circle"></i>
        </a>
      </p>
    </div>

    <div
      *ngSwitchCase="'key_value_list'"
      [formArrayName]="controlKey"
    >
      <div>
        <div *ngFor="let keyValueGroup of form.controls[controlKey].controls; index as i">
          <div
            [formGroup]="keyValueGroup"
            class="row"
            style="max-width: 600px; overflow: auto;"
          >
            <div
              *ngFor="let key of keyValueGroup.controls | keys"
              class="col-md-5"
            >
              {{parameter.options ? parameter.options[key] || key : key}}
              <br>
              <input
                [formControlName]="key"
                class="form-control"
                [id]="controlKey + '_' + key + '_' + i"
                type="text"
                pattern="{{parameter.pattern}}"
                (change)="valueChanged($event)"
              >
            </div>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addKeyValueControl()"
          >Add New <i class="fas fa-plus-circle"></i></a>
        </p>
      </div>
    </div>

    <div
      *ngSwitchCase="'true_false_value'"
      [formGroupName]="controlKey"
    >
      <div
        class="row"
        style="max-width: 600px; overflow: auto;"
      >
        <div class="col-md-6">
          {{parameter.options ? parameter.options['trueValue'] || 'trueValue' : 'trueValue'}}
          <input
            [formControlName]="'trueValue'"
            class="form-control"
            [disabled]="parameter.disabled"
            [ngClass]="{'disabled': parameter.disabled}"
            [id]="controlKey + '_' + 'trueValue'"
            type="text"
            pattern="{{parameter.pattern}}"
            (change)="valueChanged($event)"
          >
        </div>
        <div class="col-md-6">
          {{parameter.options ? parameter.options['falseValue'] || 'falseValue' : 'falseValue'}}
          <input
            [formControlName]="'falseValue'"
            class="form-control"
            [disabled]="parameter.disabled"
            [ngClass]="{'disabled': parameter.disabled}"
            [id]="controlKey + '_' + 'falseValue'"
            type="text"
            pattern="{{parameter.pattern}}"
            (change)="valueChanged($event)"
          >
        </div>
      </div>
    </div>

    <div
      *ngSwitchCase="'account'"
      [formGroupName]="controlKey"
    >
      <div
        *ngIf="parameter.value"
        class="row"
        style="max-width: 600px; overflow: auto;"
      >
        <div
          *ngFor="let key of parameter.value | keys"
          class="col-md-6"
        >
          {{parameter.options ? parameter.options[key] || key : key}}
          <input
            [formControlName]="key"
            class="form-control"
            [disabled]="parameter.disabled"
            [ngClass]="{'disabled': parameter.disabled}"
            [id]="controlKey + '_' + key"
            type="text"
            pattern="{{parameter.pattern}}"
            (change)="valueChanged($event)"
          >
        </div>
      </div>
    </div>

    <div
      *ngSwitchCase="'account_list'"
      [formArrayName]="controlKey"
    >
      <div>
        <div *ngFor="let accountGroup of form.controls[controlKey].controls; index as i">
          <div
            [formGroup]="accountGroup"
            class="row"
            style="max-width: 600px; overflow: auto;"
          >
            <div
              *ngFor="let key of accountGroup.controls | keys"
              class="col-md-5"
            >
              {{parameter.options ? parameter.options[key] || key : key}}
              <br>
              <input
                [formControlName]="key"
                class="form-control"
                [id]="controlKey + '_' + key + '_' + i"
                type="text"
                pattern="{{parameter.pattern}}"
                (change)="valueChanged($event)"
              >
            </div>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addAccountControl()"
          >
            Add New
            <i class="fas fa-plus-circle"></i>
          </a>
        </p>
      </div>
    </div>

    <div
      *ngSwitchCase="'remote_desktop_conn_list'"
      [formArrayName]="controlKey"
    >
      <div>
        <div *ngFor="let connectionGroup of form.controls[controlKey].controls; index as i" 
          style="border: 3px solid grey;">
          <div
            [formGroup]="connectionGroup"
            class="row"
            style="max-width: 600px; overflow: auto; margin: 8px"
          >
            <div
              *ngFor="let key of connectionGroup.controls | keys"
              class="col-md-5"
            >
              {{parameter.options ? parameter.options[key] || key : key}}
              <br>
              <input
                [formControlName]="key"
                class="form-control"
                [id]="controlKey + '_' + key + '_' + i"
                type="text"
                pattern="{{parameter.pattern}}"
                (change)="valueChanged($event)"
              >
            </div>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addRemoteDesktopControl()"
          >
            Add New
            <i class="fas fa-plus-circle"></i>
          </a>
        </p>
      </div>
    </div>

    <ng-container
      *ngSwitchCase="'third_party_auth_list'"
      [formArrayName]="controlKey"
    >
      <hr>
      <ng-container>
        <ng-container *ngFor="let thirdPartyAuthGroup of form.controls[controlKey].controls; index as i">
          <div
            [formGroup]="thirdPartyAuthGroup"
            class="row"
            style="max-width: 600px; overflow: auto;"
          >
            <div class="col-md-12">
              <b>Callback URL:
              </b>{{Group.generateThirdPartyCallbackUrl(thirdPartyAuthGroup.value, parameter["groupId"])}}
            </div>
            <div class="col-md-12">
              <b>SP Init Login URL:
              </b>{{Group.generateThirdPartySPLoginRoute(thirdPartyAuthGroup.value, parameter["groupId"], true)}}
            </div>

            <ng-container *ngFor="let key of thirdPartyAuthGroup.controls | keys">
              <div
                class="col-md-5"
                *ngIf="key !== 'groupId'"
              >
                {{parameter.options ? parameter.options[key] || key : key}}
                <br>
                <ng-container *ngIf="key === 'protocol' || key === 'provider'">
                  <select
                    [id]="controlKey + '_' + key + '_' + i"
                    [formControlName]="key"
                    class="form-control"
                    [ngClass]="{'disabled': parameter.disabled}"
                    required
                    autofocus
                    (change)="valueChanged($event)"
                  >
                    <option value="">Please Select</option>
                    <ng-container *ngIf="key === 'protocol'">
                      <option value="{{ThirdPartyAuthProtocol.oauth2}}">OAuth 2.0</option>
                    </ng-container>
                    <ng-container *ngIf="key === 'provider'">
                      <option value="{{ThirdPartyAuthProvider.openidc}}">OpenID Connect</option>
                    </ng-container>
                  </select>
                </ng-container>
                <ng-container *ngIf="key !== 'protocol' && key !== 'provider'">
                  <input
                    [formControlName]="key"
                    class="form-control"
                    [id]="controlKey + '_' + key + '_' + i"
                    type="text"
                    pattern="{{parameter.pattern}}"
                    [ngClass]="{'disabled': parameter.disabled}"
                    autofocus
                    (change)="valueChanged($event)"
                  />
                </ng-container>
              </div>
            </ng-container>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
          <hr>
        </ng-container>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addThirdPartyAuthControl()"
          >
            Add New
            <i class="fas fa-plus-circle"></i>
          </a>
        </p>
      </ng-container>
    </ng-container>

    <div
      *ngSwitchCase="'size'"
      [formGroupName]="controlKey"
    >
      <div
        class="row"
        style="max-width: 600px; overflow: auto;"
      >
        <div
          *ngFor="let key of parameter.options | keys"
          class="col-md-6"
        >
          {{parameter.options[key] || key}}
          <input
            [formControlName]="key"
            class="form-control"
            [disabled]="parameter.disabled"
            [ngClass]="{'disabled': parameter.disabled}"
            [id]="controlKey + '_' + key"
            type="number"
            (change)="valueChanged($event)"
          >
        </div>
      </div>
    </div>

    <div
      *ngSwitchCase="'size_list'"
      [formArrayName]="controlKey"
    >
      <div>
        <div *ngFor="let sizeGroup of form.controls[controlKey].controls; index as i">
          <div
            [formGroup]="sizeGroup"
            class="row"
            style="max-width: 600px; overflow: auto;"
          >
            <div
              *ngFor="let key of sizeGroup.controls | keys"
              class="col-md-5"
            >
              {{parameter.options ? parameter.options[key] || key : key}}
              <br>
              <input
                [formControlName]="key"
                class="form-control"
                [id]="controlKey + '_' + key + '_' + i"
                type="number"
                (change)="valueChanged($event)"
              >
            </div>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addSizeControl()"
          >
            Add New
            <i class="fas fa-plus-circle"></i>
          </a>
        </p>
      </div>
    </div>

    <div
      *ngSwitchCase="'queue_screen_list'"
      [formArrayName]="controlKey"
    >
      <div>
        <div *ngFor="let queueGroup of form.controls[controlKey].controls; index as i">
          <div
            [formGroup]="queueGroup"
            class="row"
            style="max-width: 600px; overflow: auto;"
          >
            <div
              *ngFor="let key of queueGroup.controls | keys"
              class="col-md-5"
            >
              {{parameter.options ? parameter.options[key] || key : key}}
              <br>
              <input
                [formControlName]="key"
                class="form-control"
                [id]="controlKey + '_' + key + '_' + i"
                type="text"
                (change)="valueChanged($event)"
              >
            </div>
            <div class="col-md-2">
              <br>
              <a
                class="cursor-pointer text-danger"
                tabindex
                (click)="removeListItem(i)"
              >
                <i class="fas fa-minus-circle"></i>
              </a>
            </div>
          </div>
        </div>
        <p>
          <a
            class="cursor-pointer"
            tabindex
            (click)="addQueueControl()"
          >
            Add New
            <i class="fas fa-plus-circle"></i>
          </a>
        </p>
      </div>
    </div>

    <div *ngSwitchCase="'tree'">
      <tree-view
        [items]="parameter.options"
        [disabled]="parameter.disabled"
        [unselectAvailable]="false"
        [multiSelectAvailable]="false"
        [deleteItemAvailable]="false"
        (select)="treeSelect($event)"
        [levelSelectionAvailable]="false"
        (levelSelectionChanged)="levelSelectionChangedTreeViewItem($event)"
      >
      </tree-view>
    </div>

    <div
      *ngSwitchCase="'checkbox_list'"
      [formArrayName]="controlKey"
      class="row"
    >
      <div
        *ngFor="let keyValueGroup of form.controls[controlKey].controls; index as i"
        [formGroup]="keyValueGroup"
        class="form-group col-sm-4"
        style="max-width: 600px; overflow: auto;"
      >
        <div class="custom-control custom-checkbox my-1 mr-sm-2">
          <input
            [id]="controlKey + '_value_' + i"
            [formControlName]="'value'"
            type="checkbox"
            class="custom-control-input"
            [attr.disabled]=parameter.disabled
            (change)="valueChanged($event)"
          >
          <label class="custom-control-label cursor-pointer"
                 [for]="controlKey + '_value_' + i"
          >
            {{keyValueGroup.value.key}}
          </label>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'dynamic_tree'">
      <tree-view
        [id]="controlKey"
        [(items)]="parameter.options"
        (select)="treeSelect($event, true, false)"
        (remove)="removeTreeViewItem($event)"
        [unselectAvailable]="true"
        [multiSelectAvailable]="false"
        [deleteItemAvailable]="true"
        [levelSelectionAvailable]="false"
        (levelSelectionChanged)="levelSelectionChangedTreeViewItem($event)"
      >
      </tree-view>
    </div>

    <div *ngSwitchCase="'category_subskills_new'">
      <p>
        <a
          class="cursor-pointer"
          tabindex
          (click)="addCategorySubskill()"
        >
          Add New Category Subskill to selected Category Subskill
          <i class="fas fa-plus-circle"></i>
        </a>
      </p>
      <input
        [formControlName]="controlKey"
        class="form-control"
        [disabled]="parameter.disabled"
        [ngClass]="{'disabled': parameter.disabled}"
        [id]="controlKey"
        type="text"
        pattern="{{parameter.pattern}}"
        (change)="valueChanged($event)"
        #InputField
        autofocus
        (keydown)="onKeydown($event)"
      >
    </div>

    <div *ngSwitchCase="'multiselect_tree'">
      <tree-view
        [id]="controlKey"
        [(items)]="parameter.options"
        (select)="treeSelect($event, true, false)"
        [unselectAvailable]="true"
        [multiSelectAvailable]="true"
        [deleteItemAvailable]="false"
        [levelSelectionAvailable]="true"
        [levelOptions]="parameter.levelOptions"
        [disabled]="parameter.disabled"
        (levelSelectionChanged)="levelSelectionChangedTreeViewItem($event)"
      >
      </tree-view>
    </div>

    <ng-container *ngSwitchCase="'wysiwyg'">
      <quill-editor
        [attr.id]="wysiwygModel.id"
        class="col-md-12 p-0"
        [formControl]="form.controls[controlKey]"
        [theme]="wysiwygModel.editorTheme"
        [modules]="wysiwygModel.editorModules"
        (onContentChanged)="valueChanged($event)"
      >
      </quill-editor>
    </ng-container>
  </div>

  <div
    class="errorMessage alert-danger p-1"
    *ngIf="!isValid && (form.controls[controlKey].dirty && form.controls[controlKey].touched)"
  >
    {{errorMessage}}
  </div>
</div>
