<!--
    Copyright Compunetix Incorporated 2016-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<div
  class="h-100 video-container"
  [style.display]="isPipOrLoopback() ? 'block' : 'flex'"
  [hidden]="!isStreamReady"
  [ngClass]="{'animated zoomIn': animated, 'minimized': minimized}"
  #videoContainer
>

  <div
    class="video-view-control top full-width"
    [ngClass]="{'text-left': !minimized, 'text-right': minimized}"
    [hidden]="inSubWindow || minimized">
    <label
      class="btn text-white bg-gray-opacity mb-0"
      [hidden]="!displayTitle" [innerHTML]="title | safeHtml">
    </label>
    <button
      *ngIf="displayPopup && !isMobileDevice"
      role="button"
      aria-label="Open this Video In a Popup Window"
      tabindex
      class='btn cursor-pointer control-link pull-right bg-gray-opacity'
      (click)="openWindow($event)"
      (touchstart)="openWindow($event)">
      <i aria-hidden="true" class="fa fa-window-restore"></i>
    </button>
  </div>
  <div
    class="video-view-control bottom text-left full-width"
    [hidden]="inSubWindow">
    <label
      class="btn text-white mb-0"
      [hidden]="!minimized" [innerHTML]="title | safeHtml">
    </label>
    <button
      *ngIf="displayExpand"
      [hidden]="!minimized"
      role="button"
      aria-label="expand this video"
      tabindex
      class='btn cursor-pointer control-link pull-left'
      (click)="expandVideoView($event)">
      <i aria-hidden="true" class="fa fa-expand"></i>
    </button>
    <button *ngIf="displayCompress"
      [hidden]="minimized"
      role="button"
      aria-label="collapse this video"
      tabindex
      class='btn cursor-pointer control-link pull-left'
      (click)="compressVideoView($event)">
      <i aria-hidden="true" class="fa fa-compress"></i>
    </button>
    <button *ngIf="displayFlip && !isMobileApp"
      [hidden]="!canFlipCamera || minimized"
      role="button"
      aria-label="flip camera"
      tabindex
      class='btn cursor-pointer control-link pull-right'
      (click)="flipCamera($event)">
      <i aria-hidden="true" class="fa fa-undo"></i>
    </button>
  </div>

    <video
      #mainVideo
      class="video d-print-none cursor-pointer main-video full-height animated fadeIn"
      [title]="safeName"
      autoplay
      playsinline
      [id]="videoElementId"
      [style.width]="(epCameraRotation(endpoint) === '90' || epCameraRotation(endpoint) === '-90') && !isPipOrLoopback() ? videoContainer.clientHeight + 'px' : '100%'"
      [ngClass]="{
        'd-block': isMobileApp,
        'myEndpoint':endpoint.rtcId===endpointService.myEndpoint.rtcId,
        'mirror': displayInMirror,
        'fit-cover': (epCameraRotation(endpoint) !== '90' && epCameraRotation(endpoint) !== '-90') && videoAspect === VideoAspect.fill
      }"
      [style.backgroundColor]="localizationService.myLocalizationData.video_screen ? localizationService.myLocalizationData.video_screen.backgroundColor : null"
      [ngStyle]="{'transform': 'rotate(' + (epCameraRotation(endpoint) ? epCameraRotation(endpoint) : '0') + 'deg)'}"
      [hidden]="inSubWindow"
      [attr.muted]="isMuted"
      [volume]="videoVolume"
      (click)="showInMainView($event)">
    </video>

    <button *ngIf="displayRotate && !isMobileApp"
        role="button"
        aria-label="rotate this video"
       class='btn cursor-pointer rotate-control'
       style="display: none;"
       (click)="rotateVideo($event)"
       (dblclick)="$event.stopPropagation()"
    >
      <i aria-hidden="true" class="fa fa-redo"></i>
    </button>

    <div class="control-bar">
      <button class="btn control-icon" *ngIf="canCycleCamera()" (click)="showInMainView($event)">
        <i class="fa fa-sync-alt" aria-hidden="true"></i>
      </button>
      <button *ngIf="displayTakePhoto && endpointService.myEpConnected()" class="btn control-icon">
        <i *ngIf="!takingPhoto" class="fa fa-camera-retro" aria-hidden="true" (click)="takePicture(endpoint.rtcId)"></i>
        <i *ngIf="takingPhoto" class="fa fa-camera-retro camera-taking-photo" aria-hidden="true"></i>
      </button>
      <button *ngIf="displayVideoBlock" class="btn control-icon">
        <i *ngIf="!blockingVideo" class="fa fa-eye" aria-hidden="true" (click)="blockVideo(endpoint.rtcId)"></i>
        <i *ngIf="blockingVideo" class="fa fa-eye-slash" aria-hidden="true"  (click)="blockVideo(endpoint.rtcId)"></i>
      </button>
    </div>
    <div *ngIf="ngIf_showNames()" class="position-absolute" style="z-index: 1000"
      [ngStyle]="{'bottom': (isMobileApp)? '0': '30px'}"
    >
      <label
        [hidden]="displayTitle || isPipOrLoopback()"
        class="btn text-white bg-gray-opacity mb-0"
        [innerHTML]="nameTag | safeHtml">
      </label>
    </div>
</div>

<video-window
  [stream]="stream"
  [endpoint]="endpoint"
  [title]="title"
  [videoElementId]="videoElementId">
</video-window>
