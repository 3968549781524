<!--
    Copyright Compunetix Incorporated 2022-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi, frivolta, kbender
-->

<div class="top-bar"><span>{{clock}}</span> {{user?.username}}</div>

<div
  class="dashboard-nav-bar"
  *ngIf="localizationService.myLocalizationData.toolbar"
  [style.backgroundColor]="localizationService.myLocalizationData.toolbar.backgroundColor"
  [style.fontFamily]="localizationService.myLocalizationData.toolbar.fontFamily"
  [style.borderColor]="localizationService.myLocalizationData.toolbar.borderColor"
  [style.color]="localizationService.myLocalizationData.toolbar.fontColor"
>

  <nav class="navbar-expand navbar-light bg-faded">
    <div class="container-fluid">
      <div class="navbar-collapse collapse">
        <span class="navbar-text w-100"><!--placeholder--></span>
        <div class="dashboard-navbar-logo w-100 justify-content-center d-flex">
          <img
            *ngIf="localizationService.myLocalizationData.toolbar.logo"
            [attr.src]="localizationService.myLocalizationData.toolbar.logo"
            crossorigin="anonymous"
          />
        </div>

        <ul class="navbar-nav w-100 justify-content-end">
          <li class="nav-item">
            <button role="button" class="nav-link btn btn-link ng-star-inserted" title="Logout" aria-label="Logout"
                    [style.backgroundColor]="localizationService.myLocalizationData.toolbar.backgroundColor"
                    [style.fontFamily]="localizationService.myLocalizationData.toolbar.fontFamily"
                    *ngIf="dashboardService.isAuthenticated && source !== 'vcc'"
                    (click)="logout()">
              <i aria-hidden="true" class="fa-sign-out-alt fas ng-star-inserted" style="font-size: 2rem;"></i>
              <br>
              <label class="m-0"> Logout </label>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>

<div
  class="loading-screen"
  [hidden]="!dashboardService.isLoading"
>
  <img class="spinner" src="images/spinner.gif" />
  <div class="message">Please wait while we retrieve your data...</div>
</div>

<div class="dashboard-container">
  <nav class="navbar navbar-expand-lg navbar-light justify-content-between">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav mr-auto" [hidden]="!dashboardService.isAuthenticated">
        <li class="nav-item" routerLinkActive="active" *ngFor="let page of pages">
          <div *ngIf="page.accessPermission">
            <a class="nav-link" [routerLink]="navigatingThemeText + page.route" queryParamsHandling="merge">{{page.title}}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="text-right ml-auto" [hidden]="!dashboardService.isAuthenticated">
      <div class="dashboard-print d-inline-block" (click)="printScreen()">PRINT</div>
      <ng-template #popContent>
        <div style="width: 200px; height: 200px">
          <tree-view
            [items]="groupTreeRoots"
            (select)="groupTreeSelect($event)"
          >
          </tree-view>
        </div>

      </ng-template>
      <button type="button" class="btn btn-primary group-selector text-uppercase" placement="bottom left" outsideClick="true"
              [popover]="popContent"
              [disabled]="!(groupTreeRoots?.length > 0 && groupTreeRoots[0]?.children?.length > 0)">
        <span *ngIf="dashboardService.selectedGroup; else allTpl">{{dashboardService.selectedGroup.text}}</span>
        <ng-template #allTpl>All</ng-template>
      </button>
    </div>
  </nav>
  <div class="queue-filter d-flex flex-row" style="position:absolute; font-size:1.25rem; padding-left:12px">
    <div class="section p-1 mr-4" [hidden]="(!queueLanguages && !queueCategories) || !dashboardService.isAuthenticated">
      Queue Filter:
    </div>
    <select class="btn btn-primary p-1 mr-2"       
      [hidden]="!queueCategories || !dashboardService.isAuthenticated"
      [(ngModel)]="queueFilter.category"
      (change)="selectCategory(queueFilter.category)">
      <option
      *ngFor="let category of queueCategories"
      [ngValue]="category">{{category}}</option>
    </select>
    <select class="btn btn-primary p-1 mr-2"
      [hidden]="!queueLanguages || !dashboardService.isAuthenticated" 
      [(ngModel)]="queueFilter.language"
      (change)="selectLanguage(queueFilter.language)">
      <option
      *ngFor="let language of queueLanguages"
      [ngValue]="language">{{language}}</option>
    </select>
  </div>
  
  <router-outlet *ngIf="dashboardService.isAuthenticated && pageLoaded"></router-outlet>

  <div class="no-authentication-card" *ngIf="!dashboardService.isAuthenticated && pageLoaded">
    <login-screen (login)="login($event)"></login-screen>
  </div>

</div>

<div class="loading-screen" [hidden]="pageLoaded">
  <img class="spinner" src="images/spinner.gif" />
</div>

<footer></footer>
