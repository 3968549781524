/**
 * Copyright Compunetix Incorporated 2017-2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Ramadevi Gavirneni
 */

import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {
  IUser,
  PresenceStatus
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { SafeHtmlPipe } from "client/scripts/shared/pipes/safe-html.pipe";

@Component({
  selector: "user-item",
  templateUrl: "./user-item.template.html"
})
export class UserItemComponent implements OnInit {

  @Input()
  user: IUser;

  @Output("openUserInfoModal")
  openUserInfoModalEmitter: EventEmitter<IUser> = new EventEmitter<IUser>();

  public presenceStatusClass = PresenceStatus;

  avatarImageUrl: string;

  constructor(public localizationService: LocalizationService) {
    // nothing needed here
  }

  ngOnInit() {
  }

  get name(): string {
    return this.user?.name || this.user?.username;
  }

  getAvatarImageUrl() {
    return (this.avatarImageUrl) ? this.avatarImageUrl : "/images/avatar.png";
  }

  openUserInfoModal() {
    this.openUserInfoModalEmitter.emit(this.user);
  }

  presenseStatusClass(): PresenceStatus {
    return PresenceStatus.offline;
  }

  presenseStatusClassTitle(): string|number {
    return (this.localizationService.myLocalizationData.presenceStatus[this.presenceStatusClass[PresenceStatus.offline]] || this.presenceStatusClass[PresenceStatus.offline]);
  }

}
