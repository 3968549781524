/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DashboardService} from "../dashboard.service";
import {TimeService} from "../../shared/services/time.service";
import {Store} from "../../Store/store.service";
import {Subscription} from "rxjs";
import {
  CustomerStatus,
  ICustomer,
  ICustomerStatus,
  ITrendsData,
  ISkillTags
} from "companion";
import {distinctUntilChanged, map} from "rxjs/operators";
import {IBarChartItem} from "../shared/bar-chart.component";
import { IPercentageData } from "../overview/tile.component";
import {IDateRange, PickerMode} from "../shared/range-picker.component";
import {IChartData} from "../shared/trends-chart.component";
import { format, formatDuration, subDays, parseISO } from "date-fns";
import { FormatTimePipe } from "../shared/format-time.pipe";

interface ICustomerPercentages {
  inQueuePreviousHourPercentage: IPercentageData;
  inQueuePreviousWeekPercentage: IPercentageData;
}

// Dashboard Customer's Experience tab
@Component({
  selector: "dashboard-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
  providers: [FormatTimePipe]
})
export class DashboardCustomerComponent implements OnInit, OnDestroy {
  
  constructor(
    private dashboardService: DashboardService,
    private timeService: TimeService,
    private store: Store,
    private formatTimePipe: FormatTimePipe
  ) {}

  @Input() groupId: string;
  mapHeight = "0";

  callsBackgroundColors: string[];
  callsHoverBackgroundColors: string[];
  timeBackgroundColors: string[];
  timeHoverBackgroundColors: string[];
  storeSubscription: Subscription;
  customers: ICustomer[] = [];
  customersInRange: ICustomer[] = [];
  all: ICustomer[] = [];
  selectedCustomer: ICustomer = null;
  selectedCustomerId: string = null;
  customerStatus = CustomerStatus;
  refreshTimer: any;
  statusDurationTimeString = "";
  lastUpdate: string;

  customersStatus: ICustomerStatus;
  allCustomers: ICustomer;

  selectedDates: Date[];
  selectedAggregation: PickerMode;



  trendsCallsData: IChartData;
  trendsTimeData: IChartData;

  barChartItems: IBarChartItem[] = [];
  percentages: ICustomerPercentages;

  googleMapsAPIKey: { apiKey: string } = { apiKey: "" };

  ngOnInit(): void {
    this.init();
    this.refreshData(true);
    this.refreshTimer = setInterval(() => {
      this.refreshData(false);
    }, this.dashboardService.clientRefreshRate * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
    this.storeSubscription.unsubscribe();
  }

  init() {
    this.callsBackgroundColors = ["#21D59B", "#0058FF", "#FFC700"];
    this.callsHoverBackgroundColors =  ["rgba(33,213,155,0.6)", "rgba(0,88,255,0.6)", "rgba(255,199,0,0.6)"];

    this.timeBackgroundColors = ["#21D59B", "rgba(41, 203, 151, 0.3)", "#0058FF", "rgba(0, 88, 255, 0.3)"];
    this.timeHoverBackgroundColors = ["rgba(33,213,155,0.6)", "rgba(41, 203, 151, 0.2)", "rgba(0,88,255,0.6)", "rgba(0, 88, 255, 0.2)"];

    this.customersStatus = {
      totalConferencing: 0,
      totalAvailable: 0,
      totalInQueue: 0,
      totalOnHold: 0,
      inQueuePreviousHourPercentage: 0,
      inQueuePreviousWeekPercentage: 0
    };

    this.allCustomers = {
      id: "all-customers",
      name: "All Customers",
      theme: "",
      latitude: null,
      longitude: null,
      avatarImageUrl: "images/dashboard/users.png",
      status: null,
      statusStartedAt: null
    };

    this.percentages = {
      inQueuePreviousHourPercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      },
      inQueuePreviousWeekPercentage: {
        value: null,
        color: "text-black2",
        arrow: "none",
        label: ""
      }
    };

    this.selectedCustomer = this.allCustomers;
    this.selectedCustomerId = this.allCustomers.id;

    const startDate = subDays(new Date(), 6);
    const endDate = new Date();
    this.selectedDates = [startDate, endDate];

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.googleMapsAPIKey), distinctUntilChanged())
      .subscribe((googleMapsAPIKey: string) => {
        if (googleMapsAPIKey) {
          this.googleMapsAPIKey = { apiKey: googleMapsAPIKey };
        }
        else {
          this.googleMapsAPIKey = { apiKey: "" };
        }
      });

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.dashboardGroup), distinctUntilChanged())
      .subscribe(dashboardGroup => {
        if (dashboardGroup) {
          this.refreshData(true);
        }
      });
    
      this.storeSubscription = this.store.changes
      .pipe(map(data => data.language), distinctUntilChanged())
      .subscribe(language => {
        if (language) {
          this.refreshData(true);
        }
      });
      this.storeSubscription = this.store.changes
      .pipe(map(data => data.category), distinctUntilChanged())
      .subscribe(category => {
        if (category) {
          this.refreshData(true);
        }
      });
  }

  refreshData(refreshTrends) {

    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }

    this.dashboardService.fetchCustomersCurrentStatus(filterTags)
    .then((response) => {
      this.lastUpdate = response.lastUpdate;
      this.customers = response.data.customers;
      this.customersStatus = response.data.customersStatus;
      this.all = [this.allCustomers, ...this.customers, ...this.customersInRange];
      this.updatePercentages();
      this.createBarChartItems(this.customersStatus);
      this.selectCustomer(this.selectedCustomerId, false);
    })
    .catch((error) => {
      console.log("Failed to fetchCustomersCurrentStatus: ", error);
    });

    if(refreshTrends)
    {
      this.getTrends({mode : this.selectedAggregation, range: this.selectedDates}, true);
    }
  }

  updatePercentages() {
    const inQueueLastHour = this.customersStatus.inQueuePreviousHourPercentage;
    const inQueueLastWeek = this.customersStatus.inQueuePreviousWeekPercentage;

    this.percentages.inQueuePreviousHourPercentage = this.calculateTilePercentage(inQueueLastHour, "than last hour", true);
    this.percentages.inQueuePreviousWeekPercentage = this.calculateTilePercentage(inQueueLastWeek, "than same day previous week", true);
  }

  calculateTilePercentage(value: number, label: string, downIsGood?: boolean) {
    return {
      value: value > 0 ? value : -value,
      color: (value > 0 && downIsGood) || (value < 0 && !downIsGood) ? "text-red" :
        downIsGood !== undefined && value !== null ? "text-green" : "text-black2",
      arrow: value !== null ? value > 0 ? "up" : "down" : "none",
      label: label
    };
  }

  getTrends(selection?: IDateRange, getAll: boolean = true) {

    let filterTags : ISkillTags = {
      language : this.store.getState().language === "All" ? null : this.store.getState().language,
      category : this.store.getState().category === "All" ? null : this.store.getState().category,
    };
    
    if(!filterTags.category && !filterTags.language)
    {
      filterTags = null;
    }

    if (selection) {
      this.selectedAggregation = selection.mode || PickerMode.day;
      this.selectedDates = selection.range;
    }

    let from = this.selectedDates[0];
    let to = this.selectedDates[1];
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 999);

    if(getAll)
    {
      // select the all Customers...
      this.selectedCustomer.id = this.allCustomers.id;
    }

    const username = (this.selectedCustomer.id === this.allCustomers.id) ? null : this.selectedCustomer.name;
    const userTheme = (this.selectedCustomer.id === this.allCustomers.id) ? null : this.selectedCustomer.theme;
    this.dashboardService.fetchCustomersCallsStatisticsAsync(from, to, this.selectedAggregation + "s", username, userTheme, filterTags)
    .then((taskId) => {
      this.dashboardService.isLoading = true;
      this.dashboardService.monitorAsyncTask({
        taskId: taskId,
        responseType: "json",
        completeFn: (data: any) => {
          this.customersInRange = _.differenceBy(data.customersInRange as ICustomer[], this.customers, "id");
          this.all = [this.allCustomers, ...this.customers, ...this.customersInRange];
          this.generateChartData(data.calls);
          this.dashboardService.isLoading = false;
        },
        errorFn: () => {
          console.log("Async customer stats fetch failed. Check server for details.");
          this.dashboardService.isLoading = false;
        }
      });
    })
    .catch((error) => {
      console.log("Failed to fetchCustomersCallsStatistics: ", error);
    });
  }

  getStatusDurationTime() {
    if (this.selectedCustomer.statusStartedAt) {
      const timePassed: number = (Date.now() - this.timeService.serverTimeDelay - this.selectedCustomer.statusStartedAt);
      this.statusDurationTimeString = this.formatTimePipe.transform(timePassed);
    } else {
      this.statusDurationTimeString = "-";
    }
  }

  selectCustomer(customerId: string, refreshTrends = true) {
    const foundCustomer = this.customers.concat(this.customersInRange).find(customer => customer.id === customerId);
    this.selectedCustomer = foundCustomer ? foundCustomer : this.allCustomers;
    this.selectedCustomerId = this.selectedCustomer.id;
    this.getStatusDurationTime();
    let trendsSetting : IDateRange = {mode : this.selectedAggregation, range: this.selectedDates}
    if (refreshTrends) {
      this.getTrends(trendsSetting, false);
    }
  }

  formatTime(milliseconds: number): string {
    return DashboardService.msToMin(milliseconds);
  }

  formatDates(dates: Date[], granularity: PickerMode): string[] {
    return dates.map(date => {
      if(typeof date === "string")
      {
        date = parseISO(date);
      }

      switch (granularity) {
        case PickerMode.day:
          return format(date,"do MMM");
        case PickerMode.month:
          return format(date,"MMM yyyy");
        case PickerMode.week:
          return format(date,"yyyy w");
      }
    });
  }

  generateChartData(trendsData: ITrendsData) {
    this.trendsCallsData = {
      chartName: "Calls",
      stacked: false,
      labels: this.formatDates(trendsData.dates, this.selectedAggregation),
      dataSets: [
        {
          label: "Received",
          data: trendsData.outboundCalls
        },
        {
          label: "Answered",
          data: trendsData.inboundCalls
        },
        {
          label: "Abandoned",
          data: trendsData.abandonedCalls
        }
      ]
    };

    this.trendsTimeData = {
      chartName: "Time",
      stacked: true,
      labels: this.formatDates(trendsData.dates, this.selectedAggregation),
      dataSets: [
        {
          label: "Average Handle Time",
          stack: "handle time",
          data: trendsData.handleTimesAvg
        },
        {
          label: "Maximum Handle Time",
          stack: "handle time",
          data: trendsData.handleTimesMax
        },
        {
          label: "Average Hold Time",
          stack: "hold time",
          data: trendsData.holdTimesAvg
        },
        {
          label: "Maximum Hold Time",
          stack: "hold time",
          data: trendsData.holdTimesMax
        }
      ]
    };
  }

  createBarChartItems(value: ICustomerStatus) {
    this.barChartItems = [
      {
        label: "Conferencing",
        value: value.totalConferencing,
        barColor: "bar-green"
      },
      {
        label: "Available",
        value: value.totalAvailable,
        barColor: "bar-blue"
      },
      {
        label: "In Queue",
        value: value.totalInQueue,
        barColor: "bar-yellow"
      },
      {
        label: "On Hold",
        value: value.totalOnHold,
        barColor: "bar-orange"
      }
    ];
  }

  toggleMap() {
    this.mapHeight = this.mapHeight === "100%" ? "0" : "100%";
  }

  get ng_mapGoogleMapsAPIKeys(): any {
    return (this.googleMapsAPIKey && this.googleMapsAPIKey.apiKey) ? [this.googleMapsAPIKey] : null;
  }

  ng_mapGoogleMapsAPIKey(index, apiKeyElement): any {
    return apiKeyElement?.apiKey;
  }

}
