/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */

import { Observable, Observer } from "rxjs";
import { Injectable } from "@angular/core";
import { IUser, Companion, Cookie } from "companion";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

const KEEP_ALIVE_TIMEOUT = 60 * 1000;
const SITE_TOKEN_RETRY_DELAY = 2 * 1000;

/**
 * generic rest api request settings
 */
export class RestOptions {
  constructor() {
  }
}

@Injectable({
  providedIn: "root"
})
/**
 * generic rest api client methods delegate
 */
export class RestService {
  jwtToken: string;
  keepAliveTimer: any;
  siteTokenPromise : Promise<string>;
  constructor(private http: HttpClient, private restOptions: RestOptions) {}

  /**
   * generic create rest api request
   * @param path: string - request path
   * @param body: Object - request body
   * @returns an Observable who's data contains the body attr of the HTTP response
   */
  create(path: string, body: Object): Observable<any> {
    return this.request("POST", path, body).pipe(map((res: any) => res.body));
  }

  /**
   * generic post rest api request
   * @param path: string - request path
   * @param body: Object - request body
   * @returns an Observable who's data contains the body attr of the HTTP response
   */
  post(path: string, body: Object, responseType?:string): Observable<any> {
    return this.request("POST", path, body, null, responseType).pipe(map((res: any) => res.body));
  }

  /**
   * generic read rest api request
   * @param path: string - request path
   * @param body: Object - request body
   * @returns an Observable who's data contains the body attr of the HTTP response
   */
  read(path: string, search?: Object): Observable<any> {
    return this.request("GET", path, null, search).pipe(map((res: any) => res.body));
  }

  /**
   * generic update rest api request
   * @param path: string - request path
   * @param body: Object - request body
   * @returns an Observable who's data contains the body attr of the HTTP response
   */
  update(path: string, body: Object): Observable<any> {
    return this.request("PUT", path, body).pipe(map((res: any) => res.body));
  }

  /**
   * generic delete rest api request
   * @param path: string - request path
   * @returns an Observable who's data contains the body attr of the HTTP response
  */
  delete(path: string): Observable<any> {
    return this.request("DELETE", path).pipe(map((res: any) => res.body));
  }

  /**
   * upload
   * @param path: string - request path
   * @param formData: FormData - request form data
   * @returns an Observable who's data contains the body attr of the HTTP response
   */
  upload(path: string, formData?: FormData): Observable<any> {
    return this.request("POST", path, formData).pipe(map((res: any) => res.body));
  }

  /**
   * generic create rest api request
   * @param path: string - request path
   * @param method: RequestMethod - method for the request
   * @param body: Object - request body
   * @param search?: Object - search filter for the response
   * @returns an Observable HTTPResponse
   */
   private request(method: string, path: string, body?: Object, search?: Object, responseType?:string): Observable<any> {
    let options: any = {
        method: method,
        url: path,
        body: body,
        search: this.serialize(search),
        headers: new HttpHeaders()};

    options.responseType = responseType || "json";
    options.observe = "response";
    if (this.jwtToken) {
      options.headers = options.headers.set("authorization", this.jwtToken);
    }
    if (method === "GET") {
      return this.http.request(method, path, options);
    } else {
      if(!this.siteTokenPromise)
      {
        this.siteTokenPromise = this.getSiteToken();
      }

      return Observable.create((observer: any) => {
        this.siteTokenPromise.then((siteToken: string) =>
        {
          if (options.body) {
            options.body._csrf = siteToken;
          }
          options.headers = options.headers.set("csrf-token", siteToken);
          this.http.request(method, path, options).subscribe(
            (res: any) => { observer.next(res); },
            (error: any) => { observer.error(error); }
          );
        }).catch(error => observer.error(error));
      });
    }
  }

  /**
   * serialize object to string utility
   * @param obj: Object - object to serialize
   */
  private serialize(obj: any): string {
    var str = [];

    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }

    return str.join("&");
  }

  /**
   * get site token by csrf
   */
  getSiteToken(): Promise<string> {
    return this.read("/site-token").toPromise()
    .then((response) => {
      this.keepAlive();
      return Promise.resolve(response.csrf);
    })
    .catch((response: any) => {
      console.error(response.error);
      const delay = t => new Promise(resolve => setTimeout(resolve, t));
      return delay(SITE_TOKEN_RETRY_DELAY).then(() => {
        return this.getSiteToken();
      }).catch((error: any) => {
        console.log(`Failed to delay getSiteToken: ${JSON.stringify(error)}`);
      });
    });
  }

  /**
   * invalidate site token
   */
  invalidateCsrfToken() {
    this.siteTokenPromise = null;
  }

  /**
   * Keep Alive
   */
  keepAlive(): void {
    clearTimeout(this.keepAliveTimer);
    this.post("/keep-alive", {})
      .subscribe(
        (data: any) => {
          // nothing needed here
        },
        (error: any) => {
          if (error && /csrf/gmi.exec(error._body)) {
            this.siteTokenPromise = null;
          }
          console.error("KEEPALIVE ERROR:" +error);
        }
      );
    this.keepAliveTimer = setTimeout(() => {
      clearTimeout(this.keepAliveTimer);
      this.keepAlive();
    }, KEEP_ALIVE_TIMEOUT);
  }

  /**
   * login
   */
  login(user: IUser, password: string): Promise<IUser> {
    return new Promise((resolve: (data: IUser) => void, reject: (error: Error) => void) => {
      this.post("/login", {
        username: user.username,
        password: password,
        rtcId: Companion.getEndpointService().myEndpoint.rtcId
      })
      .subscribe(
        (data: any) => {
          // invalidate token so that we procure a new one next request...
          console.log("YOU ARE LOGGING IN!");
          this.invalidateCsrfToken();
          this.jwtToken = data.jwtToken;
          resolve(data.user);
        },
        (error: any) => {
          console.error(error);
          reject(new Error(error._body));
        }
      );
    });
  }

  /**
   * login without password
   */
  loginWithoutPassword(user: IUser): Promise<IUser> {
    return new Promise((resolve: (data: IUser) => void, reject: (error: Error) => void) => {
      this.post("/loginWithoutPassword", {
          username: user.username,
          rtcId: Companion.getEndpointService().myEndpoint.rtcId,
          location: window.location.pathname
      })
      .subscribe(
        (data: any) => {
          console.log("YOU ARE LOGGING IN!");
          this.invalidateCsrfToken();
          this.jwtToken = data.jwtToken;
          resolve(data.user);
        },
        (error: any) => {
          console.error(error);
          reject(new Error(error._body));
        }
      );
    });
  }

  /**
   * logout
   */
  logout(): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      Companion.getUserService().currentUser.isAuthenticated = false;
      this.post("/logout", {})
      .subscribe(
        (data: string) => {
          console.log("YOU ARE LOGGING OUT!!!");
          this.invalidateCsrfToken();
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
  
  /**
   * get user permissions.
   */
  getMyPermissions(): Promise<any> {
    return new Promise((resolve: (data: any) => void, reject: (error: Error) => void) => {
      this.post("/getMyPermissions", {})
      .subscribe(
        (data: any) => {
          resolve(data);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }

  /**
   * is authenticated
   */
  isAuthenticated(): Promise<IUser> {
    return new Promise((resolve: (data: IUser) => void, reject: (error: Error) => void) => {
      this.post("/isAuthenticated", {})
      .subscribe({next :
        (data: IUser) => {
          resolve(data);
        },
        error :
        (error: any) => {
          console.error(error);
          reject(error);
        }}
      );
    });
  }

  /**
   * Dashboard is enabled
   */
  dashboardIsEnabled(): Promise<boolean> {
    return new Promise((resolve: (data: boolean) => void, reject: (error: boolean) => void) => {
      this.post("/restapi/dashboard/enabled", {})
      .subscribe(
        () => {
          resolve(true);
        },
        (error: any) => {
          console.error(error);
          reject(false);
        }
      );
    });
  }

  /**
   * get new rtcId.
   */
  getNewRtcId(): Promise<string> {
    return new Promise((resolve: (data: string) => void, reject: (error: Error) => void) => {
      this.post("/generateNewRtcId", {})
      .subscribe(
        (data: any) => {
          resolve(data.rtcId);
        },
        (error: any) => {
          console.error(error);
          reject(error);
        }
      );
    });
  }
}
