<!--
    Copyright Compunetix Incorporated 2022
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  amaggi, frivolt
-->

<div class="customer-container">
  <last-update
    [lastUpdate]="lastUpdate | formatDate : 'hh:mm:ss a'"
    (refresh)="refreshData(true)">
  </last-update>

  <div class="customer-body">
    <div class="customer-outer">
      <!--  CUSTOMERS SELECTION-->
      <div class="customers-selection d-none d-lg-block">
        <div class="card customers-selection-card">
          <div class="card-body customers-selection-card-body">
            <!--  HEADER-->
            <div class="customer-selection-header header-text">
              <div>Customers</div>
              <div class="customers-search">
                <button class="btn search-button"><i class="fas fa-search"></i></button>
                <input #customerSearch class="form-control" type="search" placeholder="Search Customer...">
              </div>
            </div>
            <!--  ALL CUSTOMERS-->
            <div
              class="customers-list all-customers"
              (click)="selectCustomer(allCustomers.id)"
              [ngClass]="{'customer-selected' : selectedCustomer.id === allCustomers.id}"
            >
              <div class="avatar fallback">
                <img
                  class="avatar-image"
                  alt="Avatar Image"
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                >
              </div>
              <div class="customer-info">
                <div class="customer-name">{{allCustomers.name}}</div>
              </div>
            </div>
            <!--  ACTIVE CUSTOMERS-->
            <div class="customer-selection-customers">
              <div class="section-header">Active</div>
              <ul class="customers-ul">
                <li
                  class="customers-list"
                  *ngFor="let customer of customers | nameFilter : customerSearch.value : 'name'; let i = index"
                  tabIndex="{{i}}"
                  (click)="selectCustomer(customer.id)"
                  [ngClass]="{'customer-selected' : customer.id === selectedCustomer.id}"
                >
                  <div
                    class="status-bullet"
                    [ngClass]="{
                            'bullet-green': customer.status === customerStatus.Conferencing,
                            'bullet-blue' : customer.status === customerStatus.Available,
                            'bullet-yellow' : customer.status === customerStatus.InQueue,
                            'bullet-orange' : customer.status === customerStatus.OnHold
                    }"
                  ></div>
                  <div class="avatar fallback">
                    <img
                      class="avatar-image"
                      alt="Avatar Image"
                      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                      [ngStyle]="{'background-image': 'url(' + customer.avatarImageUrl + ')'}"
                    >
                  </div>
                  <div class="customer-info">
                    <div class="customer-name">{{customer.name}}</div>
                    <div class="customer-theme">{{customer.theme}}</div>
                  </div>
                </li>
              </ul>
              <hr>
              <!--  IN TRENDS CUSTOMERS-->
              <div class="section-header">In Trends</div>
              <ul class="customers-ul">
                <li
                  class="customers-list"
                  *ngFor="let customer of customersInRange | nameFilter : customerSearch.value : 'name'; let i = index"
                  tabIndex="{{i}}"
                  (click)="selectCustomer(customer.id)"
                  [ngClass]="{'customer-selected' : customer.id === selectedCustomer.id}"
                >
                  <div class="status-bullet bullet-black"></div>
                  <div class="avatar fallback"></div>
                  <div class="customer-info">
                    <div class="customer-name">{{customer.name}}</div>
                    <div class="customer-theme">{{customer.theme}}</div>
                  </div>
                </li>
              </ul>

            </div>
            <button class="btn map-button" (click)="toggleMap()">
              <span>Map</span>
              <i *ngIf="mapHeight === '100%'" class="fas fa-chevron-down"></i>
              <i *ngIf="mapHeight === '0'" class="fas fa-chevron-up"></i>
            </button>
            <map *ngFor="let m of ng_mapGoogleMapsAPIKeys;trackBy:ng_mapGoogleMapsAPIKey"
                 class="customers-map"
                 [ngStyle]="{'height': mapHeight}"
                 [customers]="customers"
                 [selectedCustomerId]="selectedCustomer.id"
                 [googleMapsAPIKey]="m.apiKey">
            </map>

            <div class="customer-selection-legend">
              <hr class="legend-divider">
              <div class="legend">
                <div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-green"></div>
                    <div>Conferencing</div>
                  </div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-yellow"></div>
                    <div>In Queue</div>
                  </div>
                </div>
                <div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-blue"></div>
                    <div>Available</div>
                  </div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet bullet-orange"></div>
                    <div>On Hold</div>
                  </div>
                </div>
                <div>
                  <div class="legend-item">
                    <div class="status-bullet legend-bullet"></div>
                    <div>Offline</div>
                  </div>
                  <div class="legend-item">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  TRENDS-->
      <div class="trends container-fluid">
        <!--    Header-->
        <div class="card header-card">
          <div class="card-body trends-header">
            <div class="row justify-content-between">
              <div class="col-lg-auto col-12">
                <div class="d-none d-lg-block">
                  <div class="header-text">Selected Customer</div>
                  <div class="header-customer-name">{{selectedCustomer.name}}</div>
                </div>

                <div class="d-lg-none">
                  <div class="form-group">
                    <select class="form-control"
                            [(ngModel)]="selectedCustomerId"
                            (change)="selectCustomer(selectedCustomerId)">
                      <option
                        *ngFor="let customer of all"
                        [ngValue]="customer.id">{{customer.name}}</option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="col-lg-auto col-6">
                <bar-chart
                  *ngIf="selectedCustomer.id === 'all-customers'"
                  class="trends-header-all-customer-status"
                  [items]="barChartItems"
                ></bar-chart>
                <div
                  *ngIf="selectedCustomer.id !== 'all-customers'"
                  class="trends-header-customer-status"
                >
                  <div>
                    <div class="header-text">Status</div>
                    <div class="legend-item">
                      <div
                        class="status-bullet legend-bullet"
                        [ngClass]="{'bullet-green': selectedCustomer.status === customerStatus.Conferencing,
                            'bullet-blue' : selectedCustomer.status === customerStatus.Available,
                            'bullet-yellow' : selectedCustomer.status === customerStatus.InQueue,
                            'bullet-orange' : selectedCustomer.status === customerStatus.OnHold
                            }"
                      ></div>
                      <div class="trends-customer-status">{{customerStatus[selectedCustomer.status]}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-auto col-6">
                <div
                  *ngIf="selectedCustomer.id === 'all-customers'"
                  class="trends-header-all-customer-in-queue"
                >
                  Calls in queue
                  <div class="in-queue-body">
                    <div class="in-queue-value">{{customersStatus.totalInQueue}}</div>
                    <div>
                      <div class="in-queue-percentage">
                        <perc-compare [percentageData]="percentages.inQueuePreviousHourPercentage"></perc-compare>
                        <perc-compare [percentageData]="percentages.inQueuePreviousWeekPercentage"></perc-compare>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="trends-header-status-duration" *ngIf="selectedCustomer.id !== 'all-customers'">
                  <div class="header-text">Status Duration</div>
                  <div class="trends-status-duration">{{statusDurationTimeString}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--    Charts-->
        <div class="calls-chart h-100">
          <div class="calls-card-header">
            <div class="calls-card-header-title">Trends</div>
            <range-picker
              [selectedAggregation]="selectedAggregation"
              [selectedDates]="selectedDates"
              (rangePickerChanged)="getTrends($event, false)">
            </range-picker>
          </div>

          <div class="card calls-card trend-card">
            <div class="card-body" style="height: 100%">
              <trends-bar-chart [chartData]="trendsCallsData"
                                [backgroundColors]="callsBackgroundColors"
                                [hoverBackgroundColors]="callsHoverBackgroundColors"
                                [chartTitle]="'Calls'"></trends-bar-chart>
            </div>
          </div>
          <div class="card calls-card trend-card">
            <div class="card-body" style="height: 100%">
              <trends-bar-chart class="d-block h-100"
                                [chartData]="trendsTimeData"
                                [chartTitle]="'Time (hh:mm:ss)'"
                                [backgroundColors]="timeBackgroundColors"
                                [hoverBackgroundColors]="timeHoverBackgroundColors"
                                [tooltipFormatterFn]="formatTime"></trends-bar-chart>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
