<!--
    Copyright Compunetix Incorporated 2016-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  lcheng, kbender
-->
<div
  class="animated bg-white participant-panel"
  role="dialog"
  aria-label="Participant Panel"
  tabindex="0"
  [ngClass]="{'fadeInLeft': !isMobileApp && position === 'left',
              'fadeInRight position-right': !isMobileApp && position === 'right',
              'mobile': isMobileApp
             }"
  [id]="'participant-panel-' + position"
  [style.color]="localizationService.myLocalizationData.participant_panel.fontColor"
  [style.background]="localizationService.myLocalizationData.participant_panel.backgroundColor"
  [style.top]="localizationService.myLocalizationData.participant_panel.top"
  [style.bottom]="ngStyleBottom"
  [style.left]="localizationService.myLocalizationData.participant_panel.left"
  [style.right]="localizationService.myLocalizationData.participant_panel.right"
  [style.width]="localizationService.myLocalizationData.participant_panel.width"
  *ngIf="dataLoaded"
>
  <div *ngIf="layout === 'list' && localizationService.myLocalizationData.participant_panel?.listTypes">
    <div
      class="input-group"
      *ngIf="(listTypes.includes('sp') || listTypes.includes('op') && (availableLanguages || availableSkills))"
    >
      <select class="form-control"
          [(ngModel)]="currentSkill"
          [hidden]="!availableSkills"
          (change)="toggleFilter()">
          <option
          *ngFor="let skill of availableSkills"
          [ngValue]="skill">{{getLocalizedSkillText(skill)}}</option>
      </select>
      <select class="form-control"
          [(ngModel)]="currentLanguage"
          [hidden]="!availableLanguages"
          (change)="toggleFilter()">
          <option
          *ngFor="let language of availableLanguages"
          [ngValue]="language">{{getLocalizedLanguageText(language)}}</option>
      </select>
    </div>
    <div *ngFor="let list of lists; let index = index">
      <div class=" panel-header" [ngStyle]="{'margin-top' : index > 0 ? '20px' : '0'}">
        <div class="col-12">
          <button
            tabindex
            class="btn btn-link"
            aria-label="Back"
            *ngIf="isMobileApp"
            (click)="backToMenu()"
          >
            <i
              class="fas fa-chevron-left"
              aria-hidden="true"
            ></i>
            Back
          </button>
          <button
            *ngIf="index === 0 && !isMobileApp"
            tabindex
            class="btn-link close"
            aria-label="Close"
            (click)="closePanel(true)"
          >
            <i
              [ngClass]="{'icon-large': !isMobileApp}"
              class="fas fa-times icon-close"
              aria-hidden="true"
            ></i>
          </button>
          <h2
            class="text-center panel-header-title"
            [style.color]="localizationService.myLocalizationData.participant_panel.titleColor"
          >
            <i
              [ngClass]="{'icon-large': !isMobileApp}"
              class="fas fa-user"
              aria-hidden="true"
            ></i>
            {{localizationService.myLocalizationData.participant_panel.listTypes[list.listType]}}
            <span
              *ngIf="!list.hideTotalNumber"
              class="badge badge-pill badge-info xx-small counter-tag"
            >
              {{onlineEndpointsCount(list)}}
            </span>
          </h2>
        </div>
      </div>
      <div class="conainer-fluid panel-participant-list">
        <endpoint-list
          [endpoints]="list.endpoints"
          [conference]="conference"
          [isMaxParticipantsReached]="
              numberOfParticipants() >=
              (localizationService.myLocalizationData.participant_panel.maxParticipantsToConnect || 2)"
          [viewMode]="viewMode"
          [listType]="list.listType"
          [transferHasSelected]="transferHasSelected"
          [showMyself]="list.showMyself"
          [canMakePhoneCall]="canMakePhoneCall"
          [guestToAnswerEp]="guestToAnswerEp"
          [numberOfParticipants]="numberOfParticipants()"
          (peerChat)="peerChat($event)"
          (toggleTransferSelection)="toggleTransferSelection($event)"
          (peerVideoChat)="peerVideoChat($event)"
          (disconnectPeer)="disconnectPeer($event)"
          (hangupRingingCall)="hangupRingingCall($event)"
          (retrievePeer)="retrievePeer($event)"
          (toggleEndpointVideo)="toggleEndpointVideo($event)"
          (startMonitor)="startMonitor($event)"
          (exitMonitor)="exitMonitor($event)"
          (pushToTalk)="pushToTalk($event)"
          (toggleNotepad)="toggleNotepad($event)"
          (openGuestInfoModal)="openGuestInfoModal($event)"
          (dialOut)="dialOut()"
          (cancelCall)="cancelCall($event)"
          (openKeypad)="openKeypad()"
          [canTransferTo]="canTransferTo"
          [canConferenceIn]="canConferenceIn"
          (openOperatorInfoModal)="openOperatorInfoModal($event)"
        ></endpoint-list>
      </div>
      <div class="conainer-fluid panel-participant-list">
        <user-list
          [users]="list.users"
          [conference]="conference"
          [viewMode]="viewMode"
          [listType]="list.listType"
          [showMyself]="list.showMyself"
          (openUserInfoModal)="openUserInfoModal($event)"
        ></user-list>
      </div>
    </div>
  </div>
  <div *ngIf="layout === 'tabs'">
    <div class=" panel-header">
      <div class="col-12">
        <button
          tabindex
          class="close btn-link"
          aria-label="Close"
          (click)="closePanel(true)"
        >
          <i
            class="fas fa-times"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div class="conainer-fluid panel-participant-list">
      <div>
        <div class="col-12">
          <div
            class="btn-group"
            role="group"
          >
            <button
              *ngFor="let list of lists"
              type="button"
              class="btn"
              [ngClass]="{
                  'btn-primary': lists.indexOf(list) === tabIndex,
                  'btn-secondary': lists.indexOf(list) !== tabIndex
                }"
              (click)="toggleTab(list)"
            >
              <!--{{localizationService.myLocalizationData.participant_panel.listTypes[list.listType]}} -->
              {{ list.confName | truncate:"15":'...' }}
              <label
                class="badge-pill badge-info"
              >
                {{onlineEndpointsCount(list)}}
              </label>
            </button>
          </div>
        </div>
      </div>
      <div>
        <endpoint-list
          [endpoints]="lists[tabIndex].endpoints"
          [conference]="conference"
          [isMaxParticipantsReached]="
              numberOfParticipants() >=
              (localizationService.myLocalizationData.participant_panel.maxParticipantsToConnect || 2)"
          [viewMode]="viewMode"
          [listType]="lists[tabIndex].listType"
          [transferHasSelected]="transferHasSelected"
          [showMyself]="lists[tabIndex].showMyself"
          [canMakePhoneCall]="canMakePhoneCall"
          [guestToAnswerEp]="guestToAnswerEp"
          [numberOfParticipants]="numberOfParticipants()"
          (peerChat)="peerChat($event)"
          (toggleTransferSelection)="toggleTransferSelection($event)"
          (peerVideoChat)="peerVideoChat($event)"
          (disconnectPeer)="disconnectPeer($event)"
          (hangupRingingCall)="hangupRingingCall($event)"
          (retrievePeer)="retrievePeer($event)"
          (toggleEndpointVideo)="toggleEndpointVideo($event)"
          (startMonitor)="startMonitor($event)"
          (exitMonitor)="exitMonitor($event)"
          (pushToTalk)="pushToTalk($event)"
          (toggleNotepad)="toggleNotepad($event)"
          (openGuestInfoModal)="openGuestInfoModal($event)"
          (dialOut)="dialOut()"
          (cancelCall)="cancelCall($event)"
          (openKeypad)="openKeypad()"
          (openOperatorInfoModal)="openOperatorInfoModal($event)"
        >
        </endpoint-list>
      </div>
      <div>
        <user-list
          [users]="lists[tabIndex].users"
          [conference]="conference"
          [viewMode]="viewMode"
          [listType]="lists[tabIndex].listType"
          [showMyself]="list.showMyself"
          (openUserInfoModal)="openUserInfoModal($event)"
        ></user-list>
      </div>
    </div>
  </div>
</div>
