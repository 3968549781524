/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { QuillModule } from "ngx-quill";
import { VideoListComponent } from "./video/video-list.component";
import { LoopbackComponent } from "./loopback/loopback.component";
import { ParticipantPanelComponent } from "./participant/participant-panel.component";
import { EndpointListComponent } from "./endpoint/endpoint-list.component";
import { EndpointSelectListComponent } from "./endpoint/endpoint-select-list.component";
import { EndpointSelectItemComponent } from "./endpoint/endpoint-select-item.component";
import { GuestItemComponent } from "./endpoint/guest-item.component";
import { OperatorItemComponent } from "./endpoint/operator-item.component";
import { UserListComponent } from "./user/user-list.component";
import { UserItemComponent } from "./user/user-item.component";
import { ChatRoomComponent } from "./chatroom/chatroom.component";
import { SettingsPanelComponent } from "./settings/settings-panel.component";
import { SettingsComponent } from "./settings/settings.component";
import { VideoItemComponent } from "./video/video-item.component";
import { VideoControlComponent } from "./video/video-control.component";
import { VideoWindowComponent } from "./video/video-window.component";
import { ChatRoomSidebar } from "./chatroom/chatroom-sidebar.component";
import { ChatRoomChatBox } from "./chatroom/chatroom-chatbox.component";
import { ChatRoomMessageHistory } from "./chatroom/chatroom-messagehistory.component";
import { ChatRoomUserBox } from "./chatroom/chatroom-userbox.component";
import { ChatRoomSendBox } from "./chatroom/chatroom-sendbox.component";
import { ChatRoomListItem } from "./chatroom/chatroom-listitem.component";
import { ChatRoomSearchBox } from "./chatroom/chatroom-searchbox.component";
import { ChatRoomList } from "./chatroom/chatroom-roomlist.component";
import { MapComponent } from "./map/map.component";

import { DeviceNameTranslatePipe } from "./settings/device-name-translate.pipe";
import { ResolutionNamePipe } from "./settings/resolution-name.pipe";
import { FindRoomPipe } from "./chatroom/find-room.pipe";
import { SearchRoomPipe } from "./chatroom/search-room.pipe";

import { HttpClientModule } from "@angular/common/http";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoginModule } from "../login/login.module";
import { FooterModule } from "../layout/footer/footer.module";
import { LayoutModule } from "../layout/layout.module";
import { InspectModule } from "./inspect/inspect.module";
import { TroubleshootModalComponent } from "./troubleshoot/troubleshoot-modal.component";
import { FormModule } from "../form/form.module";
import { ConcatEndpointNamePipe } from "./endpoint/concat-endpoint-name.pipe";
import { TroubleshootWindowComponent } from "./troubleshoot/troubleshoot-window.component";
import { NotepadComponent } from "./notepad/notepad.component";
import { HelperModule } from "../helper/helper.module";
import { DialOutModule } from "../dial-out/dial-out.module";
import { MenuPanelComponent } from "./menu/menu-panel.component";
import { NgxSliderModule } from "ngx-slider-v2";
import { ChatRoomSelectParticipantComponent } from "./chatroom/chatroom-selectParticipant.component";
import { FilterParticipantPipe } from "./participant/filter-participant.pipe";
import { ContentPresenterModule } from "../content-presenter/content-presenter.module";
import { SharedModule } from "../shared/shared.module";
import { TransferDialogComponent } from "./endpoint/transfer-dialog.component";
import { RemoteDesktopModule } from "../remote-control/remote-desktop.module";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AngularSvgIconModule,
        SharedModule,
        DialOutModule,
        BsDropdownModule.forRoot(),
        LoginModule,
        FooterModule,
        FormModule,
        LayoutModule,
        InspectModule,
        QuillModule,
        HelperModule,
        NgxSliderModule,
        ContentPresenterModule,
        SharedModule,
        RemoteDesktopModule
    ],
  declarations: [
    VideoListComponent,
    VideoItemComponent,
    VideoControlComponent,
    LoopbackComponent,
    ParticipantPanelComponent,
    EndpointListComponent,
    GuestItemComponent,
    OperatorItemComponent,
    EndpointSelectListComponent,
    EndpointSelectItemComponent,
    UserListComponent,
    UserItemComponent,
    ChatRoomComponent,
    ChatRoomSidebar,
    ChatRoomChatBox,
    ChatRoomSendBox,
    ChatRoomMessageHistory,
    ChatRoomUserBox,
    ChatRoomListItem,
    ChatRoomSearchBox,
    ChatRoomList,
    ChatRoomSelectParticipantComponent,
    SettingsPanelComponent,
    SettingsComponent,
    MenuPanelComponent,
    MapComponent,
    VideoWindowComponent,
    VideoWindowComponent,
    ResolutionNamePipe,
    DeviceNameTranslatePipe,
    FindRoomPipe,
    SearchRoomPipe,
    ConcatEndpointNamePipe,
    FilterParticipantPipe,
    TroubleshootModalComponent,
    TroubleshootWindowComponent,
    TransferDialogComponent,
    NotepadComponent
  ],
  bootstrap: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    VideoListComponent,
    VideoItemComponent,
    VideoControlComponent,
    LoopbackComponent,
    EndpointSelectListComponent,
    EndpointSelectItemComponent,
    ParticipantPanelComponent,
    EndpointListComponent,
    GuestItemComponent,
    OperatorItemComponent,
    UserListComponent,
    UserItemComponent,
    ChatRoomComponent,
    ChatRoomSidebar,
    ChatRoomChatBox,
    ChatRoomSendBox,
    ChatRoomMessageHistory,
    ChatRoomUserBox,
    ChatRoomListItem,
    ChatRoomSearchBox,
    ChatRoomList,
    ChatRoomSelectParticipantComponent,
    SettingsPanelComponent,
    SettingsComponent,
    MenuPanelComponent,
    MapComponent,
    VideoWindowComponent,
    ResolutionNamePipe,
    DeviceNameTranslatePipe,
    FindRoomPipe,
    SearchRoomPipe,
    TroubleshootModalComponent,
    TransferDialogComponent,
    NotepadComponent
  ],
  providers: [ParticipantPanelComponent]
})
export class ConferenceModule {}
