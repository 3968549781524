/**
 * Copyright Compunetix Incorporated 2016-2019
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {LocalizationService} from "../../localization/localization.service";
import {
  Browser,
  Companion,
  IEndpoint,
  IEndpointRef,
  IEndpointService,
  IRTCService,
  UserService,
  VideoAspect
} from "companion";
import {VideoWindowComponent} from "./video-window.component";
import { SafeHtmlPipe } from "client/scripts/shared/pipes/safe-html.pipe";

@Component({
  selector: "video-item",
  templateUrl: "./video-item.template.html",
})

/**
 * video item view
 */
export class VideoItemComponent implements OnInit, OnChanges {
  /**
   * video windows for streams
   */
  @ViewChild(VideoWindowComponent) videoWindow: VideoWindowComponent;
  inSubWindow: boolean = false;
  /**
   * endpoint which this video view belongs to
   */
  @Input() endpoint: IEndpoint;
  /**
   * stream which this video view rendering
   */
  @Input() stream: MediaStream;

  @Input() videoElementId: string;

  @Input() displayTitle: boolean = true;
  @Input() displayPopup: boolean = true;
  @Input() displayExpand: boolean = true;
  @Input() displayRotate: boolean = true;
  @Input() displayTakePhoto: boolean = false;
  @Input() displayCompress: boolean = true;
  @Input() displayFlip: boolean = true;
  @Input() animated: boolean = true;
  @Input() displayInFullHeight: boolean = false;
  @Input() isMuted: boolean = false;
  @Input() displayInMirror: boolean = false;
  @Input() participants: number = 0;
  @Input() videoAspect: VideoAspect;
  @Input() videoVolume: number = 1;
  @Input() isPrimaryPIP: boolean = false;
  @Input() takingPhoto: boolean = false;
  @Input() displayVideoBlock: boolean = false;
  @Input() isMainVideo: boolean = false;

  minimized: boolean = false;
  VideoAspect = VideoAspect;
  isStreamReady = false;
  fps: number;
  resolution: string;
  blockingVideo: boolean = false;


  @ViewChild("videoContainer") public videoContainer: any;
  @ViewChild("mainVideo") public mainVideo: any;

  @Output("showInMainView") showInMainViewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("expandVideoView") expandVideoViewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("compressVideoView") compressVideoViewEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output("takePic") takePic: EventEmitter<string> = new EventEmitter<string>();

  public endpointService: IEndpointService = Companion.getEndpointService();

  myEndpoint: IEndpoint = this.endpointService.myEndpoint;
  rtcService: IRTCService = Companion.getRTCService();

  @Input() isMobileApp: boolean = false;
  
  /**
   * if this is a mobile device
   */
  isMobileDevice: boolean = Browser.isMobile();
  private nameSafeText: string = "";

  get safeName(): string {
    return this.nameSafeText;
  }

  constructor(public localizationService: LocalizationService,
    protected safeTextPipe: SafeHtmlPipe) {
    //
  }
  getSafeHtml(text: string): string {
    if (text) {
        return this.safeTextPipe.transform(text) as string;
    }
    return "";
  }

  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  ngOnInit() {
    let isStreamReadyTimer = setTimeout(() => {
      clearTimeout(isStreamReadyTimer);
      this.isStreamReady = true;
    }, 1000);
    this.nameSafeText = this.getSafeText(this.endpoint.name);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'endpoint': {
            // do endpoint input change stuff?
            this.nameSafeText = this.getSafeText(this.endpoint.name);
          }
        }
      }
    }
  }

  openWindow(event: Event) {
    // to prevent duplicate touch & click event trigger
    event.preventDefault();
    event.cancelBubble = true;
    this.videoWindow.open(() => {
      this.inSubWindow = false;
    });
    this.inSubWindow = true;
  }

  showInMainView(event: Event) {
    this.showInMainViewEmitter.emit({ endpoint: this.endpoint, stream: this.stream });
  }

  expandVideoView(event: Event) {
    // to prevent duplicate touch & click event trigger
    event.preventDefault();
    event.cancelBubble = true;
    this.minimized = false;
    this.expandVideoViewEmitter.emit({ endpoint: this.endpoint, stream: this.stream });
  }
  compressVideoView(event: Event) {
    // to prevent duplicate touch & click event trigger
    event.preventDefault();
    event.cancelBubble = true;
    this.minimized = true;
    this.compressVideoViewEmitter.emit({ endpoint: this.endpoint, stream: this.stream });
  }
  isPIP(): boolean {
    return this.endpoint && this.endpoint.rtcId === this.myEndpoint.rtcId ;
  }
  isPipOrLoopback(): boolean {
    return this.isPrimaryPIP || this.videoElementId.startsWith("loopback-video-");
  }

  /**
   * @returns true if the the mobile app is active, the video item is loopback and the device
   * has multiple cameras it can activate.
   */
  canCycleCamera() : boolean
  {
    return this.isPipOrLoopback() && this.isMobileApp && Companion.getDeviceService().cameraOptions.length > 1;
  }

  get title(): string {
    let result: string = "";
    if (this.displayTitle) {
      // if this is a presentation, 
      if(this.stream["streamName"] === "screen" || this.stream["content"])
      {
        result += this.localizationService.myLocalizationData.video_screen.dataTitle ? 
        this.localizationService.myLocalizationData.video_screen.dataTitle : "Data";
      }
      else
      {
        result += this.endpoint.name;
      }
    }
    return result;
  }
  get canFlipCamera(): boolean {
    let result: boolean = false;
    result = this.isMobileDevice && Companion.getDeviceService().cameraOptions.length > 0;
    return result;
  }

  ngIf_showNames(): boolean {
    return this.localizationService.myLocalizationData.video_screen?.showNames || false;
  }

  get nameTag(): string {
    let name = this.endpoint.uiName || this.endpoint.name;
    // If we have better information, use it.
    if (this.endpoint.userId) {
      let userData = UserService.getSharedInstance().findUserById(this.endpoint.userId);
      if (userData) {
        name = userData.name || userData.username || name;
      }
    }
    if (!name || name.startsWith("undefined")) {
      // Don't want name showing as undefined! Load the name from the up to date endpoint list
      let ep: IEndpoint = this.endpointService.getEndpointById(this.endpoint.rtcId);
      if (ep) {
        name = ep.uiName || ep.name;
      }
      else {
        name = "";
      }
    }
    return name;
  }

  flipCamera(event: Event): void {
    // to prevent duplicate touch & click event trigger
    event.preventDefault();
    Companion.getDeviceService().switchCamera();
  }

  takePicture(rtcId: string) {
    this.takePic.emit(rtcId);
  }

  blockVideo(rtcId)
  {
    this.blockingVideo = !this.blockingVideo;
    console.log("Block Video", this.blockingVideo);
    this.videoWindow.stream.getVideoTracks()[0].enabled = !this.blockingVideo;

    if(this.blockingVideo)
    {
      this.endpoint.note += "\n Endpoint's Video was Blocked \n";
      Companion.getRTCService().sendServerMessage("note", { rtcId: this.endpoint.rtcId, note: this.endpoint.note });
    }
  }
  epCameraRotation(epRef: IEndpointRef) { 
    let ep: IEndpoint = Companion.getEndpointService().getEndpointById(epRef.rtcId);
    return ep?.cameraRotation || "0";
  }
}
