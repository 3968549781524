/**
 * Copyright Compunetix Incorporated 2017-2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng, kbender
 */
export { IChatRoom } from "./chatroom/chatroom.interface";
export { ChatRoom } from "./chatroom/chatroom";
export { IChatRoomService } from "./chatroom/chatroom.service.interface";


export { IEndpointBase } from "./endpoint/endpoint.base.interface";
export {
  IEndpoint,
  IEndpointRef,
  PresenceState,
  PresenceStatus,
  StatusReason,
  IConnector,
  CallbackType,
  ConnectionType,
  VoiceDirection,
  IQueueStatus,
  CameraPermission,
  InviteToConnectResponse,
  TargetType,
  IPushToTalkEvent,
  SocketServiceProtocol
} from "./endpoint/endpoint.interface";
export { Endpoint } from "./endpoint/endpoint";
export { IEndpointService, IConnectingOperator } from "./endpoint/endpoint.service.interface";
export { EndpointService } from "./endpoint/endpoint.service";
export { IConferenceService } from "./conference/conference.service.interface";
export { ConferenceService } from "./conference/conference.service";
export { ActiveConferenceEvent, ActiveConferenceSm, ActiveConferenceEventResult, ActiveConferenceState } from "./conference/conference-sm";
export { PeerConnectionSm, PeerStateEventResult, PeerConnectionState, PeerConnectionEvent } from "./conference/peer-sm";
export { ClientState, ClientEvent, ClientContextData } from "./conference/client-sm";

export {
  IConferenceBase,
} from "./conference/conference.base.interface";
export {
  RoomType,
  IConferenceRef,
  IConferenceExpanded,
  IActiveConference,
  IMonitorConference,
  IQueueConference,
  IConferenceExpandedBase,
  IExpandedActiveConference,
  IExpandedMonitorConference,
  IExpandedQueueConference,
  IConferenceUpdate,
  IExpandedUsersConference
} from "./conference/conference.interface";
export { Conference, ConferenceUtil, QueueConference, ActiveConference, MonitorConference, ExpandedQueueConference, ExpandedActiveConference, ExpandedMonitorConference, ExpandedUsersConference} from "./conference/conference";
export { IAlert, AlertLevel, AlertCode, AlertHandlerCallbackOptions } from "./alert/alert.interface";
export { Alert } from "./alert/alert";

export { IMessage } from "./message/message.interface";
export { IMessageLog } from "./message/message-log.interface";
export { Message } from "./message/message";
export { IMessageService } from "./message/message.service.interface";


export { IRole } from "./role/role.interface";
export { Role } from "./role/role";
export { IRoleService } from "./role/role.service.interface";

export { Permission, PermissionLevel, PermissionType } from "./role/permission";

export { IUser } from "./user/user.interface";
export { User } from "./user/user";
export { IUserService } from "./user/user.service.interface";
export { UserService } from "./user/user.service";

export {
  IGroup,
  IGroupStatus,
  IMessagingConfig,
  IMessagingTemplate,
  ILicense,
  ThirdPartyAuthProtocol,
  ThirdPartyAuthProvider,
  IThirdPartyAuthConfig,
  IPasswordPolicy,
  IQueueAccessData,
  IQueueConfig,
  IRemoteDesktopConfig,
  IRemoteDesktopConn,
  Feature,
  RoleName,
  ITwilioAccount,
  IAuthenticationConfig,
  IThirdPartyAPIIntegration
} from "./group/group.interface";
export { Group } from "./group/group";
export { PasswordChecker } from "./security/password.checker";

export { ISharedFile, ISharedFileRef, FileTransferStatus } from "./sharedfile/sharedfile.interface";
export { SharedFile } from "./sharedfile/sharedfile";
export { ISharedFileService } from "./sharedfile/sharedfile.service.interface";

export { GlobalService } from "./services/global.service";
export { IRTCClient, PresentationMode, VideoMediaConnectionMode } from "./services/rtc.client.interface";
export { IRTCService } from "./services/rtc.service.interface";
export { IDeviceService } from "./services/device.service.interface";

export {
  IAgent,
  IAgentsStatus,
  AgentStatus,
  IAgentsCurrentStatus,
  ICallsCurrentStatus,
  ICallsDailyStatus,
  ICallsData,
  ICallsGroup,
  ICustomer,
  ICustomerStatus,
  ICustomersCurrentStatus,
  ITrendsData,
  CustomerStatus,
  ICallsStatistics
} from "./calls/calls-statistics.interface";

export {
  BrowserResource,
  BrowserResourceAccessEvent,
  IBrowserResourceAccessEvent,
  IDevice,
  SystemReportFrequency
} from "./report/report.interface";
export { IRtpStats } from "./util/stats";

export { IFilter } from "./report/filter.interface";
export { ISkillSet, ISkillTags, ISkillsSet, ICategory, ISkillProficiency } from "./skills/skill.interface";
export {
  IReportDefinition,
  IReportField,
  IAgentSummaryRecord,
  IClientSummaryRecord,
  ISystemReportRecord,
  IKioskSummaryRecord,
  ReportType,
  IConferenceReportRecord,
  IReservationEventRecord
} from "./report/report.interface";

export {
  ILocalization,
  IMediaChannelSettingOption,
  IToolBarItem,
  IQueueSelectionItem,
  IQueueSelection,
  IMenuItem
} from "./localization/localization.interface";
export {
  IFlavor,
  FlavorParameterKey,
  Languages,
  ConferenceMode,
  Bandwidth,
  RecordMode
} from "./localization/flavor.interface";

export { ICallsTotal } from "./calls/calls-statistics.interface";

export { IParameter, ParameterType, IAccountParameter, ISize } from "./form/parameter.interface";


export { IEntry, IEntryResponse, IEntryField } from "./entry/entry.interface";

export { DeviceInfo } from "./settings/device-info";
export { VideoResolution } from "./settings/video-resolution";

export { IVideo, VideoAspect } from "./video/video.interface";
export { IPosition } from "./video/position.interface";
export { Position } from "./video/position";
export { IVideoFilter } from "./video/video-filter.interface";

export { CPMode } from "./video/CPMode";
export { ICPMode } from "./video/CPMode.interface";

export { LogUtil } from "./util/log";
export { EasyRTCService } from "./services/rtc.service.easy";

export { AsyncTaskStatus } from "./async/async-task.interface";

export { VideoFilterFactory, SegmentMethod } from "./video/video-filter.factory";
export {
  IConfig,
  IVccConfig,
  IWebConfig,
  ISSLConfig,
  IICEConfig,
  IFTPConfig,
  IDBConfig,
  IClientSettings,
  CollectLogsMode,
  IRTCConfig
} from "./settings/config.interface";

export { Cookie } from "./util/cookie";
export { Hash } from "./util/hash";
export { UUID } from "./util/uuid";
export { SDPUtil } from "./util/sdp";
export { Browser } from "./util/browser";
export { StatsUtil } from "./util/stats";
export { IPCStats } from "./util/stats";
export { FlavorUtil } from "./localization/flavor-util";
export { ConfigUtil } from "./settings/config-util";
export { MediaUtil } from "./util/media";
export { CryptoUtil } from "./util/crypto";

// Stuff used in this file.
import { DeviceService } from "./services/device.service";
import { CryptoUtil } from "./util/crypto";
import { ConferenceService } from "./conference/conference.service";
import { IConferenceService } from "./conference/conference.service.interface";
import { IRTCClient } from "./services/rtc.client.interface";
import { IDeviceService } from "./services/device.service.interface";
import { IChatRoomService } from "./chatroom/chatroom.service.interface";
import { EndpointService } from "./endpoint/endpoint.service";
import { MessageService } from "./message/message.service";
import { RoleService } from "./role/role.service";
import { IUserService } from "./user/user.service.interface";
import { IRoleService } from "./role/role.service.interface";
import { IMessageService } from "./message/message.service.interface";
import { ChatRoomService } from "./chatroom/chatroom.service";
import { IEndpointService } from "./endpoint/endpoint.service.interface";
import { UserService } from "./user/user.service";
import { IRTCService } from "./services/rtc.service.interface";
import { EasyRTCService } from "./services/rtc.service.easy";
import { ISharedFileService } from "./sharedfile/sharedfile.service.interface";
import { SharedFileService } from "./sharedfile/sharedfile.service";

export class Companion {
  public static init() {
    CryptoUtil.init();
  }
  public static getChatRoomService(): IChatRoomService {
    return ChatRoomService.getSharedInstance();
  }
  public static getEndpointService(): IEndpointService {
    return EndpointService.getSharedInstance();
  }
  public static getConferenceService(): IConferenceService {
    return ConferenceService.getSharedInstance();
  }
  public static getMessageService(): IMessageService {
    return MessageService.getSharedInstance();
  }
  public static getRoleService(): IRoleService {
    return RoleService.getSharedInstance();
  }
  public static getUserService(): IUserService {
    return UserService.getSharedInstance();
  }
  public static getRTCService(): IRTCService {
    return EasyRTCService.getSharedInstance();
  }
  public static getRTCClient(): IRTCClient {
    return this.getRTCService().rtcClient;
  }
  public static getDeviceService(): IDeviceService {
    return DeviceService.getSharedInstance();
  }
  public static getSharedFileService(): ISharedFileService {
    return SharedFileService.getSharedInstance();
  }
}
