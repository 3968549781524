/**
 * Copyright Compunetix Incorporated 2018
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
export class Permission {
  public static getDefaultPermissions(): any {
    let results: any = Object();
    for (let permissionType in PermissionType) {
      if (isNaN(Number(permissionType))) {
        results[permissionType] = PermissionLevel.None;
      }
    }
    return results;
  }
}
export enum PermissionLevel {
  None,
  InGroup,
  Public,
  InGroupOrPublic,
  Always
}

export enum PermissionType {
  seeActiveConference = "seeActiveConference",
  createActiveConference = "createActiveConference",
  modifyActiveConference = "modifyActiveConference",
  deleteActiveConference = "deleteActiveConference",
  seeActiveEndpointInConference = "seeActiveEndpointInConference",
  createActiveEndpointInConference = "createActiveEndpointInConference",
  modifyActiveEndpointInConference = "modifyActiveEndpointInConference",
  deleteActiveEndpointInConference = "deleteActiveEndpointInConference",
  transferActiveEndpointFromConferenceToConference = "transferActiveEndpointFromConferenceToConference",
  connectActiveEndpointInConference = "connectActiveEndpointInConference",
  disconnectActiveEndpointInConference = "disconnectActiveEndpointInConference",
  standbyActiveEndpointInConference = "standbyActiveEndpointInConference",
  adjustActiveEndpointParticipation = "adjustActiveEndpointParticipation",
  revokeActiveEndpointPresentationToken = "revokeActiveEndpointPresentationToken",
  seeActiveEndpointInQueue = "seeActiveEndpointInQueue",
  modifyActiveEndpointInQueue = "modifyActiveEndpointInQueue",
  deleteActiveEndpointInQueue = "deleteActiveEndpointInQueue",
  transferActiveEndpointFromQueueToConference = "transferActiveEndpointFromQueueToConference",
  disconnectActiveEndpointInQueue = "disconnectActiveEndpointInQueue",
  switchActiveEndpointCameraInCall = "switchActiveEndpointCameraInCall",
  seeStoredConference = "seeStoredConference",
  createStoredConference = "createStoredConference",
  modifyStoredConference = "modifyStoredConference",
  deleteStoredConference = "deleteStoredConference",
  activateStoredConference = "activateStoredConference",
  seeReservedConference = "seeReservedConference",
  createReservedConference = "createReservedConference",
  modifyReservedConference = "modifyReservedConference",
  deleteReservedConference = "deleteReservedConference",
  seeContact = "seeContact",
  createContact = "createContact",
  modifyContact = "modifyContact",
  deleteContact = "deleteContact",
  seeService = "seeService",
  createService = "createService",
  modifyService = "modifyService",
  deleteService = "deleteService",
  seeUsers = "seeUsers",
  createUsers = "createUsers",
  modifyUsers = "modifyUsers",
  deleteUsers = "deleteUsers",
  kickOrLockUsers = "kickOrLockUsers",
  seeGroups = "seeGroups",
  createChildGroups = "createChildGroups",
  modifyChildGroups = "modifyChildGroups",
  deleteChildGroups = "deleteChildGroups",
  seeConnectors = "seeConnectors",
  seePartitions = "seePartitions",
  configurePartitions = "configurePartitions",
  seeMcuConfig = "seeMcuConfig",
  modifyMcuConfig = "modifyMcuConfig",
  databaseChange = "databaseChange",
  confirmPendingDownload = "confirmPendingDownload",
  seeQsfpJackStatus = "seeQsfpJackStatus",
  seeRj45JackStatus = "seeRj45JackStatus",
  seeAlarms = "seeAlarms",
  acknowledgeAlarms = "acknowledgeAlarms",
  configurePasswordPolicy = "configurePasswordPolicy",
  configureConnProfs = "configureConnProfs",
  seeBillingData = "seeBillingData",
  annotateBillingData = "annotateBillingData",
  deleteBillingData = "deleteBillingData",
  actAsOperator = "actAsOperator",
  silentMonitorCall = "silentMonitorCall",
  seeRoles = "seeRoles",
  createRoles = "createRoles",
  modifyRoles = "modifyRoles",
  deleteRoles = "deleteRoles",
  seeThemes = "seeThemes",
  createThemes = "createThemes",
  modifyThemes = "modifyThemes",
  deleteThemes = "deleteThemes",
  seePages = "createPages",
  createPages = "createPages",
  modifyPages = "modifyPages",
  serverRestart = "modifyPages",
  seeSysLogs = "seeSysLogs",
  seeEntry = "seeEntry",
  canManageMessageTemplate = "canManageMessageTemplate",
  canManageMessageConfig = "canManageMessageConfig",
  canManageAuthentication = "canManageAuthentication",
  canManageVideoLayout = "canManageVideoLayout",
  seeAdvancedThemeManager = "seeAdvancedThemeManager",
  canManageVCC = "canManageVCC",
  allowDashboardReports = "allowDashboardReports"
}
