/**
 * Copyright Compunetix Incorporated 2022
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, frivolta
 */

import {Component, HostListener, Input, OnInit, ViewChild} from "@angular/core";
import {ChartConfiguration} from "chart.js";
import {BaseChartDirective} from "ng2-charts";
import {LocalizationService} from "../../localization/localization.service";

export interface IChartData {
  chartName: string;
  stacked: boolean;
  labels: ChartConfiguration<"bar">["data"]["labels"];
  dataSets: IChartDataSet[];
}

export interface IChartDataSet {
  label: string;
  stack?: string;
  data: number[];
}

// Bar chart to visualize calls and time trends charts
// Used in Agent's and Customer's
@Component({
  selector: "trends-bar-chart",
  templateUrl: "./trends-chart.component.html",
  styleUrls: ["./trends-chart.component.scss"],
})
export class TrendsChartComponent implements OnInit {

  constructor(private localizationService: LocalizationService) {}

  @Input() set chartData(value: IChartData) {
    this._chartData = value;
    this.assembleChartData();
  }
  get chartData(): IChartData {
    return this._chartData;
  }

  private _chartData: IChartData;

  @Input() tooltipFormatterFn: (value) => string;
  @Input() chartTitle: string;
  @Input() backgroundColors: string[];
  @Input() hoverBackgroundColors: string[];
  @ViewChild(BaseChartDirective) chartEl: BaseChartDirective;

  isLoading = true;
  datasets: ChartConfiguration<"bar">["data"]["datasets"] = [];

  options = {};

  @HostListener("window:beforeprint", ["$event"])
  onBeforePrint(event) {
    this.chartEl.chart.resize(900, 200);
  }

  @HostListener("window:afterprint", ["$event"])
  onAfterPrint(event) {
    this.chartEl.chart.resize();
  }

  ngOnInit() {
    this.options = {
      interaction: {
        mode: "index"
      },
      animation: {
        onProgress: (context) => {
          if (context.initial) {
            this.isLoading = false;
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: this.chartTitle,
          align: "start",
          color: "#131523",
          padding: {
            top: 2,
            bottom: 0
          },
          font: {
            size: 20,
            family: this.localizationService.myLocalizationData.page.fontFamily,
            weight: "bold",
            lineHeight: 1
          }
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            usePointStyle: true,
            padding: 10,
            textAlign: "right"
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let data = context?.dataset?.data[context.dataIndex];
              if (data && this.tooltipFormatterFn) {
                data = this.tooltipFormatterFn(data);
              }

              let label = context.dataset.label || "";

              if (label) {
                label += ": " + data;
              }

              return label;
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          grid : {
            display : false
          }
        },
        y: {
          ticks: {
            precision: 0,
            callback: (value) => {
              if (value && this.tooltipFormatterFn) {
                return this.tooltipFormatterFn(value);
              }
              else {
                return value;
              }
            }
          }
        }
      }
    };
  }

  assembleChartData() {
    this.datasets = [];
    this.chartData?.dataSets?.forEach((dataSet: IChartDataSet, index: number) => {
      this.datasets.push({
        label: dataSet.label,
        data: dataSet.data,
        stack: dataSet.stack,
        minBarLength: 1,
        maxBarThickness: 15,
        backgroundColor: this.backgroundColors[index],
        hoverBackgroundColor: this.hoverBackgroundColors[index],
        barPercentage: 0.6,
        categoryPercentage: this.chartData.stacked ? 0.3 : 0.4,
        borderRadius: 20,
        borderSkipped: "bottom"
      });
    });
  }
}
