/**
 * Copyright Compunetix Incorporated 2016-2023
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  amaggi, kbender
 */

import {Component, OnDestroy, OnInit} from "@angular/core";
import {ITreeViewItem, TreeViewItem} from "../shared/components/tree-view/tree-item";
import {GroupManagementService} from "../group-management/group-management.service";
import {DashboardService} from "./dashboard.service";
import {ActivatedRoute, Router} from "@angular/router";
import { Companion, IVccConfig, IUser, ISkillTags, IQueueConfig, ConferenceUtil } from "companion";
import {LocalizationService} from "client/scripts/localization/localization.service";
import {ConfigService} from "client/scripts/config/config.service";
import {Store} from "client/scripts/Store/store.service";
import {distinctUntilChanged, map} from "rxjs";
import {UserManagementService} from "../user-management/user-management.service";
import {Subscription} from "rxjs";
import { format } from "date-fns";
import { IThirdPartyAPIIntegration } from "../../../companion/group/group.interface";
import { RestService } from "../shared/services/rest.service";

interface IPage {
  title: string;
  route: string;
  accessPermission: boolean;
}

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {

  refreshTimer: any;
  lastUpdate: string;
  clockTimer: any;
  groupTreeRoots: TreeViewItem[];
  navigatingThemeText = "default";
  theme = "default";
  pageLoaded = false;
  user: IUser;
  clock = "";
  pages: IPage[];
  source: string;
  queueFilter: ISkillTags = { language: "All", category: "All"};
  queueCategories : string[];
  queueLanguages : string[];
  storeSubscription: Subscription;
  thirdPartyAPIIntegrationSettings: IThirdPartyAPIIntegration = {};

  constructor(private groupManagementService: GroupManagementService,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private configService: ConfigService,
              public dashboardService: DashboardService,
              public localizationService: LocalizationService,
              private userManagementService: UserManagementService,
              private restService: RestService
  ) {}


  ngOnInit(): void {
    this.source = this.route.snapshot.queryParamMap.get("source");

    this.theme = (this.route.snapshot.params["style"]) ? this.route.snapshot.params["style"] : "default";
    this.navigatingThemeText = "/" + this.theme;
    this.updateUserInfo();

    this.localizationService.getLocalizationData(this.route.snapshot.url[0].path)
    .then(() => console.log("Localization data successfully loaded"))
    .catch((error) => {
      console.log("Failed to getLocalizationData: ", error);
    });

    this.configService.getVCCSettings()
    .then((settings: IVccConfig) => {
      this.dashboardService.clientRefreshRate = settings && settings.clientRefreshRate ? settings.clientRefreshRate : 1;
      return this.getGroupTree();
    }).then(() =>
    {
      if(this.store.getState().dashboardGroup.value)
      {
        this.updateQueueFilterOptions(this.store.getState().dashboardGroup.value);
      }
    })
    .catch((err: Error) => {
      console.error("GET_VCC_SETTINGS_FAILED", err);
    });
    this.fetchThirdPartyAPIIntegrationSettings();

    this.clock = format(new Date(), "HH:mm:ss");
    this.clockTimer = setInterval(() => {
      this.clock = format(new Date(), "HH:mm:ss");
    }, 1000);

    this.storeSubscription = this.store.changes
      .pipe(map(data => data.dashboardGroup), distinctUntilChanged())
      .subscribe(dashboardGroup => {
        if (dashboardGroup) {
          this.updateQueueFilterOptions(dashboardGroup.value);
        }
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshTimer);
    clearInterval(this.clockTimer);
  }

  /**
   * user log out
   */
  logout(): void {
    this.userManagementService.logout().then(() => {
      this.dashboardService.isAuthenticated = false;
    })
    .catch((error) => {console.log(error)});
  }

  updateUserInfo() {
    this.user = Companion.getUserService().currentUser;
    this.pages = [
      {
        title: "Overview",
        route: "/dashboard/overview",
        accessPermission: true
      },
      {
        title: "Agent's Performances",
        route: "/dashboard/agent",
        accessPermission: true
      },
      {
        title: "Customer's Experience",
        route: "/dashboard/customer",
        accessPermission: true
      },
      {
        title: "Reports",
        route: "/dashboard/reports",
        accessPermission: ((this.user?.permissions?.allowDashboardReports && (this.user?.permissions?.allowDashboardReports > 0)) ? true : false)
      },
    ];
    if (!this.user?.roles) {
      return;
    }
  }

  login(errorMessage: string): void {
    if (!errorMessage) {
      this.getGroupTree().then(() => {
        this.updateUserInfo();
      })
      .catch((error) => {
        console.log("Failed to getGroupTree: ", error);
      });
    }
  }

  groupTreeSelect(group: TreeViewItem) {
    this.dashboardService.selectedGroup = group;
    // show the other descendants as "selected" as well
    this.selectDescendants(group);
    this.store.update("dashboardGroup", group);
  }

  selectDescendants(group : ITreeViewItem)
  {
    group?.children?.forEach((child : TreeViewItem) =>
    {
      child.selected = true;
      this.selectDescendants(child);
    });
  }

  /**
   * select language from filter
   * @param language 
   */
  selectLanguage(language : string) {
    this.store.update("language", language);
  }

  /**
   * select category from filter
   * @param category 
   */
  selectCategory(category : string)
  {
    this.store.update("category", category);
  }

  getGroupTree(): Promise<any> {
    return this.groupManagementService
    .loadGroupTree()
    .then((groupTreeRoots: TreeViewItem[]) => {
      this.groupTreeRoots = groupTreeRoots;
      this.pageLoaded = true;
      this.dashboardService.isAuthenticated = true;
      if (!this.dashboardService.selectedGroup && groupTreeRoots?.length > 0) {
        this.dashboardService.selectedGroup = groupTreeRoots[0];
        groupTreeRoots[0].selected = true;
        this.selectDescendants(groupTreeRoots[0]);
        this.store.update("dashboardGroup", this.dashboardService.selectedGroup);
      }
    })
    .catch((err: Error) => {
      this.pageLoaded = true;
      this.dashboardService.isAuthenticated = false;
      console.error("GET_GROUP_TREE_FAILED", err);
    });
  }

  printScreen() {
      window.print();
  }

  updateQueueFilterOptions(groupId : string): void
  {
    this.groupManagementService.getGroupsQueueConfig(groupId)
    .then((queueConfig : IQueueConfig) => {
      if(queueConfig && queueConfig.skillSet)
      {
        if(queueConfig.skillSet.categorySubskills)
        {
          this.queueCategories = [];
          this.queueCategories.push("All");
          this.queueCategories = this.queueCategories.concat(ConferenceUtil.getCategoriesListFromCategorySubskills(queueConfig.skillSet.categorySubskills));
        }
        else
        {
          this.queueCategories = null;
        }

        if(queueConfig.skillSet.languages)
        {
          this.queueLanguages = [];
          this.queueLanguages.push("All");
          this.queueLanguages = this.queueLanguages.concat(queueConfig.skillSet.languages);
        }
        else
        {
          this.queueLanguages = null;
        }
      }
      else
      {
        this.queueCategories = null;
        this.queueLanguages = null;
      }
    })
    .catch((error) => {
      console.log("Failed to getGroupsQueueConfig: ", error);
    });
  }

  private fetchThirdPartyAPIIntegrationSettings() {
    this.restService
      .post("/getThirdPartyAPIIntegrationSettings", {
        theme: this.theme
      })
      .subscribe(
        (data: IThirdPartyAPIIntegration) => {
          this.thirdPartyAPIIntegrationSettings = data;
          this.store.update("googleMapsAPIKey", this.thirdPartyAPIIntegrationSettings?.googleMapsAPIKey);
        },
        (error: any) => {
          console.log("dashboard component fetchThirdPartyAPIIntegrationSettings theme:", this.theme, " error");
        }
      );
  }

}
