/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  Ramadevi Gavirneni
 */

import { Component, Input } from "@angular/core";
import { IUser, ISkillSet, ConferenceUtil } from "../../../companion";

@Component({
  selector: "user-info",
  templateUrl: "./user-info.template.html"
})
export class UserInfoComponent {
  @Input() user: IUser;

  constructor() {}

  getSkillCategoryString(tags: ISkillSet): string {
    return ConferenceUtil.getSkillSetCategoryToString(tags);
  }

  getSkillLanguageString(tags: ISkillSet): string {
    return ConferenceUtil.getSkillSetLanguageToString(tags);
  }

  getUserIdentString(): string {
    return (this.user ? this.user.username : "");
  }

}
