<!--
    Copyright Compunetix Incorporated 2017-2023
            All rights reserved
    This document and all information and ideas contained within are the
    property of Compunetix Incorporated and are confidential.

    Neither this document nor any part nor any information contained in it may
    be disclosed or furnished to others without the prior written consent of:
            Compunetix Incorporated
            2420 Mosside Blvd
            Monroeville, PA 15146
            http://www.compunetix.com

    Author:  Ramadevi Gavirneni
-->

<!--HEADER-->
<div class="d-flex">
  <div class="col-2 thumbnail left-padding-only">
    <div class="avatar"
        (click)="openUserInfoModal()">
      <img
        class="avatar-image"
        alt="Avatar Image"
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        [ngStyle]="{'background-image': 'url(' + getAvatarImageUrl() + ')'}"
      >
    </div>
  </div>
  <div class="col-10 details no-padding">
    <div class="participant-list-row d-flex h-100">
      <div class="col-8 no-padding no-overflow">
        <label [title]="name" class="text-capitalize">{{ name }}</label>
        <div class="control-section col-2 no-padding">
        </div>
      </div>
      <div class="present-section col-4 no-padding no-overflow">
        <label class="tag" [ngClass]="'text-' + (presenseStatusClass() | labelStyle)">
          <span [title]="presenseStatusClassTitle()"
                class="text-uppercase"
          >
            {{presenseStatusClassTitle()}}
          </span>
        </label>
      </div>
    </div>
  </div>
</div>

<!--BUTTONS-->
<div
  class="buttons-section margin-top text-center"
>
</div>
