/**
 * Copyright Compunetix Incorporated 2017-2024
 *         All rights reserved
 * This document and all information and ideas contained within are the
 * property of Compunetix Incorporated and are confidential.
 *
 * Neither this document nor any part nor any information contained in it may
 * be disclosed or furnished to others without the prior written consent of:
 *         Compunetix Incorporated
 *         2420 Mosside Blvd
 *         Monroeville, PA 15146
 *         http://www.compunetix.com
 *
 * Author:  lcheng
 */
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, NgZone, ElementRef, SimpleChanges, OnChanges } from "@angular/core";
import { 
  IEndpoint,
  PresenceState,
  PresenceStatus,
  Companion,
  CameraPermission,
  VoiceDirection,
  Endpoint,
  AlertCode,
  AlertLevel,
  StatsUtil
} from "companion";
import { LocalizationService } from "../../localization/localization.service";
import { TimeService } from "../../shared/services/time.service";
import { Dispatcher, ActionType } from "../../shared/services/dispatcher";
import { SafeHtmlPipe } from "client/scripts/shared/pipes/safe-html.pipe";
import { CallCenterService } from "client/scripts/call-center/call-center.service";

@Component({
  selector: "guest-item",
  templateUrl: "./guest-item.template.html"
})
export class GuestItemComponent implements OnInit, OnDestroy, OnChanges {
  observer!: IntersectionObserver;

  @Input()
  endpoint: IEndpoint;
  @Input()
  transferHasSelected: boolean;
  @Input()
  viewMode: string;

  /**
   * flag if reached maximum participants to connect
   */
  @Input()
  isMaxParticipantsReached: boolean;

  @Input() numberOfParticipants: number;

  /**
   * endpoint to answer
   */
  @Input() guestToAnswerEp: IEndpoint;

  @Output("peerChat")
  peerChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("peerVideoChat")
  peerVideoChatEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("disconnectPeer")
  disconnectPeerEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("hangupRingingCall")
  hangupRingingCallEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("retrieveData")
  retrieveDataEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("toggleTransferSelection")
  toggleTransferSelectionEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("toggleEndpointVideo")
  toggleEndpointVideoEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  @Output("openGuestInfoModal")
  openGuestInfoModalEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  /**
   * the event emitter to send event to parent to toggle notepad on the endpoint
   */
  @Output("toggleNotepad")
  toggleNotepadEmitter: EventEmitter<IEndpoint> = new EventEmitter<IEndpoint>();
  isNotepadShown: boolean;
  public presenceStateClass = PresenceState;
  public presenceStatusClass = PresenceStatus;
  public endpointService = Companion.getEndpointService();
  public conferenceService = Companion.getConferenceService();
  public rtcService = Companion.getRTCService();
  timeString: string;
  avatarImageUrl: string;
  VoiceDirection = VoiceDirection;
  CameraPermission = CameraPermission;
  getTimePassedTimer = null;
  checkNotepadShownTimer = null;
  visible: boolean = true;
  private nameSafeText: string = "";
  private switchProcessingTimeout: any;
  private associatedConfName: string;
  

  /**
   * Gets the inbound call quality score for the endpoint.
   * is a value from 0-5
   */
  get epCallQuality(): number {
    let liveEp = this.endpointService.getEndpointById(this.endpoint?.rtcId);
    if (liveEp?.callQuality)
      return liveEp.callQuality?.mos?.[0]?.inbound;
    return 0;
  }

  get safeName(): string {
    return this.nameSafeText;
  }

  /**
   * Determine if the devices warning should be displayed
   */
  get showDevicesWarning(): boolean {
    return !this.endpoint.isVoiceEp && 
           (this.endpoint.cameraPermission != CameraPermission.allowed || 
            this.endpoint.microphonePermission != CameraPermission.allowed)
  }

  /**
   * Determine if the devices error should be displayed
  */
  get showDevicesError(): boolean {
    return !this.endpoint.isVoiceEp && 
           (this.endpoint.cameraPermission != CameraPermission.allowed && 
            this.endpoint.microphonePermission != CameraPermission.allowed)
  }

  /**
   * determine if notepad view is enabled in this theme and mode
   */
  get notepadViewEnabled(): boolean {
    let result =
      this.localizationService.myLocalizationData.notepad &&
      !!this.endpoint &&
      Endpoint.getPresenceStateByStatus(this.endpoint.status) === PresenceState.dnd &&
      this.endpointService.connectedWithMeInMyConf(this.endpoint);
    return result;
  }
 
  get cameraSwitchEnabled(): boolean {
    let result = Companion.getUserService().currentUser.permissions.switchActiveEndpointCameraInCall > 0;
    return result;
  }

  get queueTransferEnabled(): boolean {
    return (this.viewMode === 'op' || this.viewMode === 'sp')  &&
      this.transferHasSelected &&
      this.endpointService.connectedWithMeInMyConf(this.endpoint) &&
      !this.localizationService.myLocalizationData.participant_panel?.transferToQueueButtonDisabled;
  }

  /**
   * Step away from a conference as the owner of it, but leave the conference intact.
   * @param endpint 
   */
  stepAwayFromConference(endpoint: IEndpoint) 
  {
    let conf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    this.endpointService.removeFromConference(this.endpointService.myEndpoint.rtcId, conf.id);
  }

  /**
   * Rejoin a conference that you have stepped away from
   * @param endpoint 
   */
  rejoinConference(endpoint: IEndpoint)
  {
    let conf = this.conferenceService.getConferenceFromEndpoint(endpoint);
    this.endpointService.addIntoConference(this.endpointService.myEndpoint.rtcId, conf.id);
  }

  peerChat(endpoint: IEndpoint) {
    this.peerChatEmitter.emit(endpoint);
  }
  peerVideoChat(endpoint: IEndpoint) {
    this.peerVideoChatEmitter.emit(endpoint);
  }
  disconnectPeer(endpoint: IEndpoint): void {
    this.disconnectPeerEmitter.emit(endpoint);
  }
  hangupRingingCall(endpoint: IEndpoint): void {
    this.hangupRingingCallEmitter.emit(endpoint.phoneNumber);
  }
  toggleTransferSelection(endpoint: IEndpoint): void {
    this.toggleTransferSelectionEmitter.emit(endpoint);
  }
  constructor(
    private timeService: TimeService,
    public localizationService: LocalizationService,
    private callCenterService: CallCenterService,
    private el: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    protected safeTextPipe: SafeHtmlPipe
  ) {
    
  }

  getSafeText(text: string): string {
    if (text) {
        return this.safeTextPipe.nonScrub(text) as string;
    }
    return "";
  }

  get categoryAndLanguage(): string
  {
    let text : string;

    if(this.endpoint.skillTags?.category)
    {
      text = this.endpoint.skillTags?.category
    }

    if(this.endpoint.skillTags?.language)
    {
      if(text)
      {
        text += " / ";
      }
      text += this.endpoint.skillTags?.language;
    }

    return decodeURIComponent(text);
  }

  putOnHold(endpoint: IEndpoint, hold : boolean) {
    this.conferenceService.actionCompleted = false;
    this.endpointService.placeEndpointOnHold(endpoint.rtcId, this.conferenceService.activeConference, hold,
    () => {
      this.conferenceService.alertHandler(AlertCode.putOnHoldSuccess,
        `Send put on hold to endpoint : ${endpoint.rtcId}, hold: ${hold}, success`, AlertLevel.info);
      this.conferenceService.actionCompleted = true;
    }, (error) => {
      this.conferenceService.alertHandler(AlertCode.putOnHoldFail, error, AlertLevel.warning);
      this.conferenceService.actionCompleted = true;
    });
  }
  
  takeOver(endpoint: IEndpoint) {
    this.endpointService.sendTakeOverConference(endpoint);
  }
  
  startTransfer(endpoint: IEndpoint)
  {
    Dispatcher.dispatch(ActionType.OpenTransferQueueDialog, endpoint);
  }
    
  cancelTransfer(endpoint: IEndpoint)
  {
    $("#TransferQueueModal").modal("hide");
  }

  toggleEndpointAudio(endpoint: IEndpoint): void {
    let videoElement: any = document.getElementById("ep-video-" + this.endpoint.rtcId);
    this.endpoint.isAudioMuted = !this.endpoint.isAudioMuted;
    if (videoElement) {
      videoElement.muted = this.endpoint.isAudioMuted;
    }
  }

  toggleEndpointVideo(endpoint: IEndpoint): void {
    endpoint.showVideo = !endpoint.showVideo;
    this.toggleEndpointVideoEmitter.emit(endpoint);
  }

  /**
   * logic for when we disable the connect button (we are connecting to someone else currently)
   * @param endpoint 
   */
  disableConnect(endpoint: IEndpoint) : boolean {
    // disable connect if 
    // 1. We are in a transition state, 
    // 2. The endpoint did not give us permission to their camera, and they are not a callback endpoint.
    // 3. We are an opeator but the theme says we don't want to let operators connect.
    // 4. We are in the process of monitoring another andpoint
    // 5. We are showing a devices warning
    return this.endpointService.myEndpoint.status === PresenceStatus.disconnecting || 
           this.endpointService.myEndpoint.status === PresenceStatus.ready || 
           endpoint.status === PresenceStatus.online  || 
           (this.endpointService.myEndpoint.status === PresenceStatus.connecting && 
             (this.guestToAnswerEp?.rtcId !== endpoint.rtcId)) ||
           (!endpoint.cameraPermission && endpoint.status !== PresenceStatus.callback) || 
           (Companion.getUserService().amIOperator() && this.localizationService.myLocalizationData.participant_panel?.disableOperatorGuestConnect) ||
           this.rtcService.rtcClient.monitoring == true ||
           this.showDevicesError;
  }

  /**
   * allow resume if
   * 1. We own the subconf
   * @param endpoint the endpoint to check
   * @returns 
   */
  allowResume(endpoint: IEndpoint) : boolean {
    let activeConf = Companion.getConferenceService().getConferenceFromEndpoint(endpoint);
    return activeConf && activeConf?.ownerId === this.endpointService.myEndpoint.userId;
  }

  /**
   * Gets the owner name of the endpoints associated conference.
   */
  getAssociatedConfName()
  {
    return this.associatedConfName;
  }

  getTimePassed() {
    if (!this.visible) {
      if (this.getTimePassedTimer) {
        clearTimeout(this.getTimePassedTimer);
      }
      return;
    }
    
    let timePassed: number = Date.now() - this.timeService.serverTimeDelay - this.endpoint.enteredOn;
    if (timePassed < 0) { // is serverTimeDelay slightly off? don't show negatives
      timePassed = 0;
    }
    let s: number = Math.floor(timePassed / 1000);
    let min: number = Math.floor(s / 60);
    let sec: number = s % 60;
    this.timeString = (min > 9 ? min : "0" + min) + ":" + (sec > 9 ? sec : "0" + sec);

    this.getTimePassedTimer = setTimeout(() => {
      clearTimeout(this.getTimePassedTimer);
      this.getTimePassed();
    }, 1000);
  }
  ngOnInit() {
    this.nameSafeText = this.getSafeText(this.endpoint.uiName);
    this.getTimePassed();
    this.checkNotepadShown();
    this.ngZone.runOutsideAngular(() => {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
           this.visible = e.isIntersecting;
           this.getTimePassed();
           this.checkNotepadShown();
        });
      });
      this.observer.observe(this.el.nativeElement);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'endpoint': {
            // do endpoint input change stuff?
            this.nameSafeText = this.getSafeText(this.endpoint.uiName || this.endpoint.name || this.endpoint.rtcId);
            this.associatedConfName = this.getSafeText(this.callCenterService.getTargetEndpointsList(this.endpoint));
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.visible = false;
    this.getTimePassed();
    this.checkNotepadShown();
    this.observer.disconnect();
  }
  checkNotepadShown() {
    if (!this.visible) {
      if (this.checkNotepadShownTimer) {
        clearTimeout(this.checkNotepadShownTimer);
      }
      return;
    }
    
    this.isNotepadShown = $("#notepad-" + this.endpoint.rtcId).length > 0;
    this.checkNotepadShownTimer = setTimeout(() => {
      clearTimeout(this.checkNotepadShownTimer);
      this.checkNotepadShown();
    }, 1 * 1000);
  }
  openGuestInfoModal() {
    this.openGuestInfoModalEmitter.emit(this.endpoint);
  }
  toggleNotepad() {
    this.isNotepadShown = !this.isNotepadShown;
    this.toggleNotepadEmitter.emit(this.isNotepadShown ? this.endpoint : null);
  }
  switchCamera() {
    if(this.switchProcessingTimeout)
    {
      return;
    }

    Companion.getEndpointService().sendSwitchCameraRequest(this.endpoint);
    this.switchProcessingTimeout = setTimeout(() => {
      clearTimeout(this.switchProcessingTimeout);
      this.switchProcessingTimeout = null;
    }, 800);
  }

  toggleRemoteControl()
  {
    Dispatcher.dispatch(ActionType.ToggleRemoteDesktop, this.endpoint);
  }

  getState() : PresenceState
  {
    return Endpoint.getPresenceStateByStatus(this.endpoint.status);
  }

  getMyState() : PresenceState
  {
    return Endpoint.getPresenceStateByStatus(this.endpointService.myEndpoint.status);
  }

  ngIf_connectButton(endpoint: IEndpoint): boolean {
    return (endpoint.status !== this.presenceStatusClass.callback_connected_external &&
            endpoint.rtcId != this.guestToAnswerEp?.rtcId &&
            this.getState() !== this.presenceStateClass.dnd && 
            this.getState() !== this.presenceStateClass.away &&
            !this.endpointService.connectedWithMeInMyConf(endpoint) && 
            (this.getMyState() !== this.presenceStateClass.dnd ||
             this.endpointService.myEndpoint.isInCustomState) &&
            !this.transferHasSelected &&
            !this.isMaxParticipantsReached);
  }

  ngIf_rejoinButton(endpoint: IEndpoint): boolean {
    return (this.endpointService.amISteppedAway(endpoint) &&
            !this.conferenceService.activeConference &&
            this.getMyState() !== this.presenceStateClass.dnd);
  }

  ngIf_selectButton(endpoint: IEndpoint): boolean {
    let status = (this.viewMode === 'op' || this.viewMode === 'sp')
    && !this.transferHasSelected
    && this.getState() !== this.presenceStateClass.away
    && endpoint.status !== this.presenceStatusClass.ringing
    && endpoint.status !== this.presenceStatusClass.connecting
    && endpoint.status !== this.presenceStatusClass.callback_connected_external
    && endpoint.status !== this.presenceStatusClass.callback
    && this.endpointService.connectedWithMeInMyConf(endpoint);

    return status;
  }

  ngIf_canChat(endpoint: IEndpoint): boolean {
    return this.endpointService.connectedWithMeInMyConf(endpoint) && !!this.localizationService.myLocalizationData.chat_panel &&
      endpoint.status != PresenceStatus.callback_connected_external && !endpoint.isVoiceEp &&
      (!this.localizationService.myLocalizationData.toolbar.toolbar_items.chat.hostOnly ||
       endpoint.isHost);
  }

}
